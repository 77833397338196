















































































import { defineComponent } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper.js'
import { user, role } from '@/plugins/User'
import { orderOpen } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        return {
            orderOpen,
        }
    },
    data() {
        return {
            drawer: false,
            userPermissions: false,
            userEmail: user?.value?.email ? user?.value?.email : '',
            envType: process.env.VUE_APP_ENV,
        }
    },
    mounted() {
        if (
            this.envType === 'production' &&
            user.value !== undefined &&
            ((user.value.type === 'amc' &&
                !['Sales', 'Specialist', 'Quality', 'Assignment Coordinator', 'External'].includes(role.value)) ||
                user.value.type === 'ascent' ||
                (user.value.type === 'office' && AscentHelper.canDo('Submit Feedback')))
        ) {
            this.userPermissions = true
        }
    },
    methods: {
        injectScript(source: string, body?: string) {
            return new Promise(function (resolve) {
                const scriptTag = document.createElement('script')

                scriptTag.type = 'text/javascript'
                scriptTag.async = true
                if (source !== '') {
                    scriptTag.src = source
                }

                if (body) {
                    const scriptTagContents = document.createTextNode(body)
                    scriptTag.appendChild(scriptTagContents)
                }

                document.head.appendChild(scriptTag)

                scriptTag.addEventListener('load', resolve)
            })
        },
    },
})
