









import { computed, defineComponent } from '@vue/composition-api'
import Broadcasting from '@/components/OrderScreens/Workflow/Broadcasting.vue'
import AssignAction from '@/components/OrderScreens/Workflow/AssignAction.vue'
import AcceptAction from '@/components/OrderScreens/Workflow/AcceptAction.vue'
import ClientApproval from '@/components/OrderScreens/Workflow/ClientApproval.vue'
import CompleteOrderAction from '@/components/OrderScreens/Customer/CustomerOrderComponents/CompleteOrderAction.vue'
import ConditionalAction from '@/components/OrderScreens/Workflow/ConditionalAction.vue'
import ReconsiderationAction from '@/components/OrderScreens/Workflow/ReconsiderationAction.vue'
import RevisionAction from '@/components/OrderScreens/Workflow/RevisionAction.vue'
import ScheduleAction from '@/components/OrderScreens/Workflow/ScheduleAction.vue'
import UWRequest from '@/components/OrderScreens/Workflow/UWRequest.vue'
import WaitingStep from '@/components/OrderScreens/Workflow/WaitingStep.vue'
import { enabledFeatures, order } from '@/plugins/order/Order'
import { Tag } from '@/types'
import AppraiserAcceptAction from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserAcceptAction.vue'
import AssignWorkflowNotice from '@/components/OrderScreens/Workflow/AssignWorkflowNotice.vue'
import EvaluateAction from '@/components/OrderScreens/Office/OrderComponents/Workflows/EvaluateAction.vue'
import UnifiedQualityAction from '@/components/OrderScreens/Workflow/UnifiedQualityAction.vue'
import OfficeAssignAction from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignAction.vue'
import { mainProduct } from '@/plugins/order/OrderProducts'
import StartKitReport from '@/components/OrderScreens/Workflow/Kit/StartKitReport.vue'
import { showError } from '@/AppLayout/helpers/snackbar.vue'

/**
 * @name ActionComponent
 * Sets the workflow step on the AMC Order Screen depending on what status the order is in.
 */

export default defineComponent({
    components: {
        Broadcasting,
        AssignAction,
        AcceptAction,
        EvaluateAction,
        AppraiserAcceptAction,
        ClientApproval,
        CompleteOrderAction,
        ConditionalAction,
        ReconsiderationAction,
        RevisionAction,
        ScheduleAction,
        UWRequest,
        WaitingStep,
        AssignWorkflowNotice,
        UnifiedQualityAction,
        OfficeAssignAction,
        StartKitReport,
    },
    props: {
        withoutAssign: {
            type: Boolean,
            default: false,
        },
        activeTab: {
            type: Number,
        },
    },
    setup() {
        const getActionComponent = computed(() => {
            if (!order.value) return

            switch (order.value.status) {
                case 'Broadcasting':
                    return 'Broadcasting'
                case 'Evaluate':
                case 'Conditional':
                    return 'EvaluateAction'
                case 'Allocate':
                    return 'OfficeAssignAction'
                case 'Assign':
                case 'Payment Hold':
                    if (order.value.tags?.find((elem: Tag) => elem.tag == 'Conditional') !== undefined)
                        return 'ConditionalAction'
                    return 'AssignWorkflowNotice'
                case 'Hold':
                    if (order.value.incremental && !order.value.incremental.assigned) {
                        return 'AssignWorkflowNotice'
                    }
                    return ''
                case 'Accept':
                    if (enabledFeatures.value.orderScreen.acceptType === 'full') return 'AppraiserAcceptAction'
                    return 'AcceptAction'
                case 'Client Approval':
                    return 'ClientApproval'
                case 'Schedule':
                    return 'ScheduleAction'
                case 'Waiting Inspection':
                    return 'WaitingStep'
                case 'Complete':
                case 'QC Revision':
                case 'UW Revision':
                case 'Reconsideration of Value':
                    if (mainProduct.value && mainProduct.value.report) {
                        return 'StartKitReport'
                    }
                    return 'CompleteOrderAction'
                case 'QC Review':
                case 'Reconsideration Review':
                case 'Internal Review':
                    return 'UnifiedQualityAction'
                case 'UW Request':
                    return 'UWRequest'
                case 'Completed':
                    if (order.value.tags.find((elem) => elem.tag == 'Delivery Required')) {
                        return ''
                    }
                    return 'RevisionAction'
                case 'Reconsideration Request':
                    return 'ReconsiderationAction'
                default:
                    return ''
            }
        })

        const showAcceptanceError = (error: string) => {
            showError(error)
        }

        return {
            order,
            getActionComponent,
            showAcceptanceError,
        }
    },
})
