var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"no-click-animation":"","transition":"dialog-bottom-transition","color":"blue-grey lighten-5"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();_vm.assignOpen = false}},model:{value:(_vm.assignOpen),callback:function ($$v) {_vm.assignOpen=$$v},expression:"assignOpen"}},[_c('v-toolbar',{attrs:{"title":""}},[_c('v-toolbar-title',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('a-btn',{attrs:{"icon":"","color":"accentBlack"},on:{"click":function($event){_vm.assignOpen = false}}},[_c('v-icon',[_vm._v("$vuetify.icons.close")])],1)],1),(_vm.order)?_c('v-card',{attrs:{"flat":"","min-height":"100vh"}},[_c('v-card-text',[(_vm.$ascent.featureEnabled('orderScreen.assignDirectives'))?_c('AssignDirectives'):_vm._e(),(((_vm.order && !_vm.order.appraiser_id) || _vm.isDeclined) && _vm.order.status !== 'Allocate')?_c('assign-action'):_vm._e(),(_vm.order.status === 'Allocate' && !_vm.order.appraiser_id)?_c('OfficeAssignAction'):_vm._e()],1),(_vm.order && _vm.order.appraiser_id && !_vm.isDeclined && _vm.order.status !== 'Evaluate')?_c('v-card-text',[(!_vm.isConditional)?_c('reassign-appraiser'):_vm._e(),(_vm.isConditional)?_c('ConditionalAction'):_vm._e()],1):_vm._e(),(
                _vm.order &&
                ((_vm.order.appraiser_id && !_vm.isDeclined) || _vm.order.status === 'Payment Hold' || _vm.order.status === 'Hold')
            )?_c('v-card-text',{staticClass:"mt-12"},[(
                    _vm.$ascent.getCurrentCustomer().type === 'office' &&
                    !_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false)
                )?_c('OfficeAssignPanel'):_vm._e(),_c('Deadlines',{staticClass:"mt-2 mb-7",attrs:{"showAlert":!_vm.order.appraiserDeadline || !_vm.order.clientDeadline}}),(_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false) && _vm.reassignmentType !== 'team')?_c('JaroAssignPanels',{attrs:{"jaro-panels":_vm.jaroPanels,"allow-assignment":false,"large-panel-viewer":true}}):_vm._e(),(
                    _vm.$ascent.getCurrentCustomer().type !== 'office' &&
                    !_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false)
                )?_c('AssignPanels'):_vm._e()],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }