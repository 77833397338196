var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ejs-dialog',{ref:"syncDialog",attrs:{"id":"dialog","width":600,"visible":false,"target":"#orderScreen","header":_vm.header,"showCloseIcon":true,"position":{ Y: 'center', Z: 'top' },"isModal":"true"}},[_c('ValidationObserver',{ref:"form",staticClass:"mt-2 pt-2 custom-height",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"pt-2 mt-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"Name","name":"contact name","outlined":"","dense":""},model:{value:(_vm.state.contact.name),callback:function ($$v) {_vm.$set(_vm.state.contact, "name", $$v)},expression:"state.contact.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"email","label":"Email","name":"contact email","outlined":"","dense":""},model:{value:(_vm.state.contact.email),callback:function ($$v) {_vm.$set(_vm.state.contact, "email", $$v)},expression:"state.contact.email"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('a-select-field',{attrs:{"rules":"required","items":_vm.otherContactTypeSelections,"name":"contact type","outlined":"","dense":"","label":"Type"},model:{value:(_vm.state.contact.contact_type),callback:function ($$v) {_vm.$set(_vm.state.contact, "contact_type", $$v)},expression:"state.contact.contact_type"}})],1),_vm._l((_vm.state.contact.phones),function(phone,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('a-select-field',{attrs:{"rules":"required","items":_vm.phoneTypes,"label":"Phone Type","name":'contact phone type' + index,"data-vv-as":"Phone Type","dense":""},model:{value:(phone.type),callback:function ($$v) {_vm.$set(phone, "type", $$v)},expression:"phone.type"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|phone_no_ext:10","name":"contact phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.phone),expression:"masks.phone"}],attrs:{"label":"Phone","type":"tel","name":"contact phone","color":"secondary","success":valid,"error-messages":errors},model:{value:(phone.phone),callback:function ($$v) {_vm.$set(phone, "phone", $$v)},expression:"phone.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align-self":"start"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.state.contact.phones.splice(index, 1)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("$vuetify.icons.trashAlt")])],1)],1)],1)],1)}),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.state.contact.phones.push({
                            phone: null,
                            type: null,
                        })}}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")]),_vm._v(" Add Phone ")],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-checkbox',{attrs:{"name":"entry","color":"success","label":"Property access?","dense":""},model:{value:(_vm.state.contact.entry),callback:function ($$v) {_vm.$set(_vm.state.contact, "entry", $$v)},expression:"state.contact.entry"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('a-btn-confirm',{attrs:{"color":"primary","loading":_vm.state.adding,"disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.submitFunction)}}},[_vm._v(" Save ")])],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }