import $axios from '@/plugins/axios'
import { GET_FEES, newOrder, setFeeType } from '@/pages/Client/NewOrderMethods'
import { order } from '@/plugins/order/Order'
import { computed, Ref, ref } from '@vue/composition-api'
import { Allocation } from '@/pages/Shared/Vendors/AssignmentRules/Priority/Partials/AssignmentClass'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import AscentHelper from '@/helpers/ascent-helper'

export const vendors: Ref<Allocation[]> = ref([])
export const teams: Ref<Allocation[]> = ref([])

export const selectedVendor = computed(() => {
    if (!newOrder.value.appraiser_id || newOrder.value.appraiser_id === 0 || vendors.value.length === 0) return ''
    return vendors.value.find((elem) => elem.id === newOrder.value.appraiser_id)?.name
})

export const selectedTeam = computed(() => {
    if (!newOrder.value.team_id || newOrder.value.team_id === null || teams.value.length === 0) return ''
    return teams.value.find((elem) => elem.id === newOrder.value.team_id)?.name
})

export const getOrderAllocation = () => {
    return $axios
        .post('v1/allocation/action/get-order-allocation', {
            order: order.value,
        })
        .then(({ data }) => {
            if (data.result === 'error') return []
            return data.rule.allocations
        })
        .catch((error) => {
            if (error.response?.data?.message) {
                showError('An error occurred while getting allocation. Error: ' + error.response.data.message)
                return
            }
            showError('An error occurred while getting allocation. Please try again.')
        })
}

export const GetNewOrderAllocation = () => {
    $axios
        .post('v1/allocation/action/get-order-allocation', {
            order: newOrder.value,
        })
        .then(({ data }) => {
            if (data.result === 'error' || data?.rule?.allocations?.length === 0) {
                newOrder.value.appraiser_id = 0
                return false
            }
            vendors.value = data.rule.allocations

            newOrder.value.appraiser_id = data.rule.allocations[0]['id'] ?? 0

            GET_FEES().then(() => {
                if (AscentHelper.customerFeatureEnabled('allocation.defaultFee', 'disclosed') === 'vendor') {
                    setFeeType(AscentHelper.customerFeatureEnabled('lang.order_form_default_fee', null))
                }
            })
        })
        .catch((error) => {
            if (error.response?.data?.message) {
                showError('An error occurred while getting allocation. Error: ' + error.response.data.message)
                return
            }
            showError('An error occurred while getting allocation. Please try again.')
        })
}

export const GetNewOrderTeamAllocation = () => {
    $axios
        .post('v1/allocation/action/get-order-allocation', {
            order: newOrder.value,
            type: 'team',
        })
        .then(({ data }) => {
            if (data.result === 'error') {
                newOrder.value.team_id = null
                return false
            }
            teams.value = data.rule.allocations
            newOrder.value.team_id = data.rule.allocations[0]['id']
        })
        .catch((error) => {
            if (error.response?.data?.message) {
                showError('An error occurred while getting team allocation. Error: ' + error.response.data.message)
                return
            }
            showError('An error occurred while getting team allocation. Please try again.')
        })
}
