


























































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import OfficeAssignPanel from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignPanel.vue'
import OfficeTeamAllocation from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/TeamAllocation/OfficeTeamAllocation.vue'
import {
    allAppraisers,
    selectedAppraiser,
    GET_OFFICE_PANELS,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { order, assignAnswer } from '@/plugins/order/Order'
import { orderProducts } from '@/plugins/order/OrderProducts'
import OverridePanelAssignDialog, {
    overrideDialog,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/OverridePanelAssignDialog.vue'
import {
    roles,
    reassignDialog,
} from '@/components/OrderScreens/Office/OrderComponents/ServiceProviders/OfficeReassignDialog.vue'
import $axios from '@/plugins/axios'
import { user } from '@/plugins/User'
import { MenuItem } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import Deadlines from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/Deadlines.vue'
import { deadlinesRequired, assignOpen } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import JaroAssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroAssignPanels.vue'
import {
    getValidJaroPanels,
    jaroPanels,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'
import AssignOverride from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignOverride.vue'

export default defineComponent({
    components: {
        AssignOverride,
        JaroAssignPanels,
        OverridePanelAssignDialog,
        OfficeAssignPanel,
        OfficeTeamAllocation,
        Deadlines,
    },
    setup() {
        deadlinesRequired.value = true
        const assignAction = ref(0)
        const actions = computed(() => {
            if (!order.value) return []
            if (!user.value || user.value.type === 'appraiser') return []
            if (!order.value.incremental) return []
            const actionItems: MenuItem[] = []
            if (user.value.type === 'Admin' || AscentHelper.canDo('Override Appraiser Assignment')) {
                actionItems.push({
                    label: 'Override Appraiser Assignment',
                    action: () => {
                        reassignDialog.value = false
                        overrideDialog.value = true
                    },
                })
            }
            return actionItems
        })

        onMounted(() => {
            $axios.get('/v1/settings/appraiser-roles').then((response) => {
                roles.value = response.data.result
            })

            if (AscentHelper.customerFeatureEnabled('usesJaroPanels', false)) {
                getValidJaroPanels({ order_id: order.value?.id, customer_id: order.value?.customer_id })
            } else {
                GET_OFFICE_PANELS({
                    type: 'order',
                    id: order?.value?.id as number,
                }).then(() => {
                    if (orderProducts.value.find((el) => el.product_name === '1004D Final Inspection')) {
                        selectedAppraiser.value = order.value?.incremental?.duplicates?.find(
                            (el) => el.duplicate_cache?.status === 'Completed',
                        )?.duplicate_cache?.appraiser_id
                    }
                })
            }
        })

        return {
            actions,
            selectedAppraiser,
            allAppraisers,
            assignAnswer,
            assignAction,
            getValidJaroPanels,
            jaroPanels,
            roles,
            order,
            assignOpen,
        }
    },
    computed: {
        selected() {
            return this.selectedAppraiser
        },
        isOverrideNeeded() {
            if (!order.value) return
            if (!order.value?.incremental) return
            return order.value.incremental.assign_override_approval
        },
    },
})
