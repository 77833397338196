



























































































import { defineComponent, computed } from '@vue/composition-api'
import { order, representativePhone } from '@/plugins/order/Order'
import ClientOrderDates from '@/components/OrderScreens/Client/OrderComponents/ClientOrderDates.vue'
import { customerPages } from '@/plugins/User'
import { Phone } from '@/types/phones'

/**
 * @name ClientOrderDetails
 * Component in Client Order Screen that gives details about the order to the client
 */

export default defineComponent({
    name: 'ClientOrderDetails',
    components: { ClientOrderDates },

    setup() {
        const showAppraiserInfo = computed(() => customerPages.value?.clientColumns?.includes('appraiserName'))
        const appraiserPhoneNumber = computed(
            () =>
                order.value?.incremental?.appraiser?.phones?.find((phone: Phone) => phone.type === 'office')
                    ?.phone_formatted,
        )

        return {
            order,
            representativePhone,
            showAppraiserInfo,
            appraiserPhoneNumber,
        }
    },
})
