















































import AppHeader from '@/AppLayout//AppHeader.vue'
import AppFooter from '@/AppLayout//AppFooter.vue'
import LeftNav from '@/AppLayout//navigation/LeftNav.vue'
import RightNav from '@/AppLayout//navigation/RightNav.vue'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import OrderScreen from '@/AppLayout//order/OrderScreen.vue'
import ModelAuditLog, { modelAuditLogOpen } from '@/components/OrderAuditLog/ModelAuditLog.vue'
import ScriptInjector from '@/ScriptInjector.vue'
import Prevent from '@/AppLayout/helpers/Prevent.vue'
import DownloadFile from '@/components/General/DownloadFile.vue'
import { user } from '@/plugins/User'
import NotificationSnackbar from '@/pages/System/Notifications/NotificationSnackbar.vue'
import { orderOpen } from '@/plugins/order/Order'
import AddReminderDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/AddReminderDialog.vue'
import { updateReminderAdded } from '@/pages/AMC/Dashboard/Processes/DashboardProcesses'
import { getSession } from '@/helpers/token-helper'
import { PlatformNotificationType } from '@/types'
import axios from 'axios'

export default defineComponent({
    name: 'App',
    metaInfo: {
        title: 'Main',
        titleTemplate: '%s | JaroDesk',
    },
    components: {
        NotificationSnackbar,
        DownloadFile,
        Prevent,
        LeftNav,
        AppHeader,
        AppFooter,
        OrderScreen,
        ScriptInjector,
        RightNav,
        AddReminderDialog,
        ModelAuditLog,
    },
    setup() {
        const isProduction = process.env.VUE_APP_ENV === 'production'
        const platformNotification = ref<PlatformNotificationType>()

        onMounted(() => {
            if (Object.keys(getSession()).length === 0) {
                axios.get('/sanctum/csrf-cookie')
            }

            axios.get('v1/jaro/setting/jaro-platform-notification').then((response) => {
                if (response && response.data && response.data.active) {
                    platformNotification.value = response.data
                }
            })
        })

        return {
            isProduction,
            user,
            orderOpen,
            updateReminderAdded,
            modelAuditLogOpen,
            platformNotification,
        }
    },
})
