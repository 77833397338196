
























































import ReassignAppraiser from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/ReassignAppraiser.vue'
import OfficeAssignAction from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignAction.vue'
import OfficeAssignPanel from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignPanel.vue'
import AssignAction from '@/components/OrderScreens/Workflow/AssignAction.vue'
import ConditionalAction from '@/components/OrderScreens/Workflow/ConditionalAction.vue'
import { reassignmentType } from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/CustomerReassignStaffAppraiser.vue'
import {
    assignOpen,
    isConditional,
    isDeclined,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AssignDirectives from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignDirectives.vue'
import AssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanels.vue'
import { order } from '@/plugins/order/Order'
import { computed, defineComponent, onMounted } from '@vue/composition-api'
import JaroAssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroAssignPanels.vue'
import {
    getValidJaroPanels,
    jaroPanels,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'
import AscentHelper from '@/helpers/ascent-helper'
import Deadlines from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/Deadlines.vue'

export default defineComponent({
    name: 'CustomerAssignAction',
    components: {
        AssignDirectives,
        ConditionalAction,
        AssignPanels,
        AssignAction,
        ReassignAppraiser,
        OfficeAssignAction,
        OfficeAssignPanel,
        JaroAssignPanels,
        Deadlines,
    },
    setup() {
        const title = computed(() => {
            if (order?.value?.appraiser_id) return 'Reassign Order'
            else return 'Assign Order'
        })

        onMounted(() => {
            if (AscentHelper.customerFeatureEnabled('usesJaroPanels', false)) {
                getValidJaroPanels({ order_id: order.value?.id, customer_id: order.value?.customer_id })
            }
        })

        return {
            assignOpen,
            order,
            isDeclined,
            isConditional,
            title,
            jaroPanels,
            reassignmentType,
        }
    },
})
