import { extend } from 'vee-validate/dist/vee-validate.full.esm'
import { required } from 'vee-validate/dist/rules.umd.js'

extend('required', required)

extend('min_without_tags', {
    params: ['min'],
    message: 'The {_field_} field must have at least {min} characters',
    validate: (value: string, args: { min: number }) => {
        return value.replace(/<[^>]*>/g, '').length >= args.min
    },
})

extend('phone_no_ext', {
    validate: (mobile: string, args: { digits: number }) => {
        return mobile.replace(/[^0-9]/g, '').length == args.digits
    },
    params: ['digits'],
    message: 'The {_field_} field must have {digits} digits',
})
