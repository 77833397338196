





























import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { defineComponent, onMounted, ref, computed } from '@vue/composition-api'

export const columnPickerDialog = ref(false as boolean)
export default defineComponent({
    props: {
        panelColumns: {
            type: [],
            default: [],
        },
        availableHeaders: {
            type: [],
            default: [],
        },
    },
    setup(props, { emit }) {
        const jaroAssignPanelColumns = ref([])
        const columnMenu = ref(false)
        const saveJaroAssignPanelColumns = () => {
            localStorage.setItem('jaro-assign-panel-columns', JSON.stringify(jaroAssignPanelColumns.value))
            emit('update-columns', jaroAssignPanelColumns.value)
            showSnackbar('Panel Columns Saved Successfully')
            columnMenu.value = false
        }

        const updateSelectedColumns = (id: number) => {
            const header = props.panelColumns.find((el, index) => {
                return id === index
            })
            if (!header) return
            jaroAssignPanelColumns.value.push(header)
        }

        const enabledHeaders = computed(() => {
            if (!jaroAssignPanelColumns.value) {
                return []
            }

            return jaroAssignPanelColumns.value.filter((header: { enabled: boolean }) => {
                return header.enabled
            })
        })

        onMounted(() => {
            if (!localStorage.getItem('jaro-assign-panel-columns')) {
                jaroAssignPanelColumns.value = props.availableHeaders
                return
            }
            jaroAssignPanelColumns.value = JSON.parse(localStorage.getItem('jaro-assign-panel-columns') ?? '') ?? []
        })

        return {
            columnPickerDialog,
            saveJaroAssignPanelColumns,
            jaroAssignPanelColumns,
            updateSelectedColumns,
            columnMenu,
            enabledHeaders,
        }
    },
})
