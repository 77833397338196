



















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {},
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },
    watch: {
        search(val: string) {
            val && val !== this.select && this.searchInvestor(val)
        },
    },
    mounted() {
        this.searchInvestor('')
    },
    methods: {
        searchInvestor(search: string) {
            this.loading = true
            this.$axios
                .post('/v1/search', {
                    val: search,
                    type: 'fee_schedule',
                    object: true,
                    limit: 250,
                })
                .then(({ data }) => {
                    this.items = data.sort((a: { name: string }, b: { name: string }) => {
                        return a.name < b.name || a.name == null ? -1 : 1
                    })
                    this.loading = false
                })
        },
    },
})
