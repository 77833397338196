

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        lenders: Array,
        currentClient: {
            type: Number,
            default: undefined,
        },
        branchLabel: {
            type: String,
            default: 'Branch',
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },
    watch: {
        search(val: string) {
            val && val !== this.select && this.searchBranch(val)
        },
        lenders() {
            this.searchBranch('')
        },
    },
    mounted() {
        this.searchBranch('')
    },
    methods: {
        searchBranch(search: string) {
            this.loading = true
            this.$axios
                .post('/v1/search', {
                    val: search,
                    type: 'branch',
                    object: true,
                    filter: this.lenders,
                    limit: 250,
                    currentClient: this.currentClient,
                })
                .then(({ data }) => {
                    this.items = data.sort((a: { name: string }, b: { name: string }) => {
                        return a.name < b.name || a.name == null ? -1 : 1
                    })
                    this.loading = false
                })
        },
    },
})
