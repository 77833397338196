


















import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import axios from '@/plugins/axios'

export default defineComponent({
    props: {
        branches: Array,
        lenders: Array,
        label: {
            type: String,
            default: 'Loan Officer',
        },
        searchType: {
            type: String,
            default: 'loanOfficer',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const loading = ref(false)
        const search = ref<string | null>(null)
        const items = ref([])

        const searchLoanOfficer = (search: string) => {
            loading.value = true

            let currentValue: number[]

            switch (true) {
                case !ctx.attrs.value:
                    currentValue = []
                    break
                case Array.isArray(ctx.attrs.value) && ctx.attrs.value[0] && typeof ctx.attrs.value[0] === 'object':
                    currentValue = (ctx.attrs.value as Array<{ id: number }>).map((item) => item.id)
                    break
                default:
                    currentValue = ctx.attrs.value as number[]
            }

            axios
                .post('/v1/search', {
                    val: search,
                    type: props.searchType,
                    object: true,
                    filter: props.branches,
                    limit: 25,
                    where: 'Active',
                    currentClient: currentValue,
                })
                .then(({ data }) => {
                    items.value = data.sort((a: { name: string }, b: { name: string }) => {
                        return a.name < b.name || a.name == null ? -1 : 1
                    })
                    loading.value = false
                })
        }

        onMounted(() => {
            searchLoanOfficer('')
        })

        watch(
            () => search.value,
            (val) => {
                val && searchLoanOfficer(val)
            },
        )

        watch(
            () => props.branches,
            () => {
                searchLoanOfficer('')
            },
        )

        watch(
            () => props.lenders,
            () => {
                searchLoanOfficer('')
            },
        )

        return {
            items,
            loading,
            search,
        }
    },
})
