import { AscentColumnModel } from '@/helpers/AscentDataAdapter'
import { customerPages, user } from '@/plugins/User'
import { defaultVisibleTableColumns, name, tableColumns } from '@/components/OrderTable/Classes/OrderTable'
import $axios from '@/plugins/axios'
import { CustomerColumnLabels } from '@/components/OrderTable/columns/ColumnDefinitions'
import { ref } from '@vue/composition-api'

export const filterByUser = () => {
    tableColumns.value = (tableColumns.value as AscentColumnModel[]).filter(
        (elem: AscentColumnModel) =>
            (customerPages.value.columns.length === 0 || customerPages.value.columns.includes(elem.field)) &&
            (elem.user?.includes(user.value?.type as string) ||
                elem.user?.includes('all') ||
                (user.value?.type === 'client' &&
                    customerPages.value.clientColumns &&
                    customerPages.value.clientColumns.includes(elem.field))),
    )
    if (user.value?.type === 'client' && !localStorage.getItem(name.value)) {
        const clientDefault = [
            'customer_order_id',
            'rep',
            'productList',
            'orderStatus',
            'addressFormatted',
            'company',
            'lenderLoanNumber',
            'borrowers',
            'requestedDate',
            'inspection',
            'clientDeadline',
        ]
        tableColumns.value.sort((a, b) => clientDefault.indexOf(a.field) - clientDefault.indexOf(b.field))
    }
}

export const changeColumnWidthAndVisibiltyForSavedUser = (savedValue: string): AscentColumnModel[] => {
    const savedColumns = JSON.parse(savedValue) as AscentColumnModel[]
    const finalColumns: AscentColumnModel[] = []
    for (let i = 0; i < savedColumns.length; i++) {
        const officialColumn = (tableColumns.value as AscentColumnModel[]).find(
            (elem: AscentColumnModel) => elem.field === savedColumns[i].field,
        )
        if (!officialColumn) continue
        officialColumn.width = savedColumns[i].width
        officialColumn.visible = savedColumns[i].visible
        finalColumns.push(officialColumn)
    }
    return finalColumns
}

export const addNewColumnsNotFound = (finalColumns: AscentColumnModel[]): AscentColumnModel[] => {
    for (let i = 0; i < tableColumns.value.length; ++i) {
        if (!finalColumns.find((final) => final.field === tableColumns.value[i].field)) {
            finalColumns.splice(i, 0, tableColumns.value[i] as AscentColumnModel)
        }
    }
    return finalColumns
}

export const useSavedColumns = (savedValue: string) => {
    let finalColumns = changeColumnWidthAndVisibiltyForSavedUser(savedValue)

    if (tableColumns.value.length !== finalColumns.length) {
        finalColumns = addNewColumnsNotFound(finalColumns)
    }

    tableColumns.value = finalColumns
    filterByUser()
}

export const changeAvailableColumns = (availableColumns: string[]) => {
    tableColumns.value = (tableColumns.value as AscentColumnModel[]).map((column: AscentColumnModel) => {
        if (!Object.keys(column).includes('showInColumnChooser')) {
            column.showInColumnChooser = availableColumns.includes(column.field as string)
        }
        return column
    })
}

export const customerColumnLabels = ref({
    lenderName: 'Lender',
    sublenderName: 'SubLender',
} as CustomerColumnLabels)

export const updateColumnLabels = () => {
    const keys = Object.keys(customerColumnLabels.value)

    tableColumns.value = (tableColumns.value as AscentColumnModel[]).map((column: AscentColumnModel) => {
        if (keys.includes(column.field)) {
            column.headerText = customerColumnLabels.value[column.field as keyof object]
        }
        return column
    })
}

export const getCustomerColumnLabels = () => {
    $axios
        .get('v1/settings/column-labels')
        .then((response) => {
            if (!response.data) return
            customerColumnLabels.value = response.data.result
            updateColumnLabels()
        })
        .catch(() => {
            return
        })
}

export const updateVisibleColumns = (visibleColumns: string[]) => {
    tableColumns.value = (tableColumns.value as AscentColumnModel[]).map((column: AscentColumnModel) => {
        column.visible = visibleColumns.includes(column.field as string)
        return column
    })
}

export const resetAvailableColumns = (setName = '') => {
    if (setName) {
        name.value = setName
    }
    const savedColumns = localStorage.getItem(name.value)
    if (savedColumns) {
        useSavedColumns(savedColumns)
        return
    }

    if (defaultVisibleTableColumns.value.length === 0) return

    changeAvailableColumns(defaultVisibleTableColumns.value)
    updateVisibleColumns(defaultVisibleTableColumns.value)
    filterByUser()
}
