


















































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import FileUpload from '@/components/General/FileUpload.vue'
import convert from 'xml-js'
import ScheduleSecondInspectionDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/ScheduleSecondInspectionDialog.vue'
import CancelRevisionDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/CancelRevisionDialog.vue'
import AddConditionsToRequestDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/AddConditionsToRequestDialog.vue'
import QcFollowUpRemoveDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/QcFollowUpRemoveDialog.vue'
import ConditionList from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/ConditionList.vue'
import $axios from '@/plugins/axios'
import { ProductForm, Milestone, File, UppyFileResponse } from '@/types'
import { ElementCompact } from 'xml-js'
import { Dialog } from '@/ascent'
import { user } from '@/plugins/User'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { getNotes } from '@/plugins/Notes'
import { returnAppraiser } from '@/components/OrderScreens/Workflow/QualityAction.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import AscentHelper from '@/helpers/ascent-helper'
import { AscentHelperType } from '@/plugins/ascent-helper'

interface ValuationResponse {
    [x: string]: ElementCompact
}

/**
 * @name CompleteOrderAction
 * Workflow step for amc and appraiser to complete the appraisal and upload report
 *
 * @SetupData error - When uploading report, will give errors of what it finds if there are errors
 * @SetupData override - sets override for the appraisal
 * @SetupComputed url - returns url of the order
 * @SetupComputed mainProduct - finds main product from the incremental order and returns that
 * @SetupComputed getAllowedFileTypes - gets file types needed to upload depending on overrides and products
 * @SetupComputed stepText - text of the current step
 * @SetupComputed showSecondInspectionButton - determines if the schedule second inspection button will show or not
 * @SetupMethod readFile - reads file uploaded and determines errors if they are there
 * @SetupMethod uploadFile - uploads file to S3
 * @SetupMethod convertToRushUW - converts order to a rush uw
 * @SetupMethod complete - completes order and sends file path to backend. Refreshes order once finished
 */

export default defineComponent({
    components: {
        QcFollowUpRemoveDialog,
        AddConditionsToRequestDialog,
        ConditionList,
        ScheduleSecondInspectionDialog,
        CancelRevisionDialog,
        ValidationObserver,
        FileUpload,
    },
    setup() {
        const loading = ref(false)
        const error = ref('')
        const actionLoading = ref(false)
        const override = ref(false)
        const modal = ref(false)
        const observer = ref({})
        const isPdf = ref(false)

        const CompletedAppraisalFileRef = ref({} as Vue & { checkFiles: () => number } & { triggerUpload: () => void })

        const url = computed(() => {
            if (!order.value) return
            return 'order/' + order.value.id
        })
        const mainProduct = computed(() => {
            if (!order.value) return
            if (!order.value?.incremental) return
            const mainProduct = orderProducts.value.filter((elem: ProductForm) => elem.form.type === 'Main Product')
            return mainProduct[0]
        })
        const getAllowedFileTypes = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return
            if (!AscentHelper.featureEnabled('orderScreen.workflow.completed.requireXml', true)) return ['.pdf', '.xml']
            if (!mainProduct.value) return
            if (override.value) return ['.pdf', '.xml']
            if (!orderProducts.value.filter((elem: ProductForm) => elem.form.type === 'Main Product')) return ['.pdf']
            if (
                mainProduct?.value?.form?.detail?.details?.xml === false ||
                mainProduct?.value?.form?.detail?.details?.xml === 0
            ) {
                return ['.pdf']
            }
            return ['.xml']
        })
        const stepText = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                case 'Complete':
                    return 'Complete Appraisal'
                default:
                    return order.value.status
            }
        })
        const showSecondInspectionButton = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return
            const secondInspectionMilestone = order.value.incremental.milestones.find(
                (milestone: Milestone) =>
                    milestone.type === 'current' && milestone.milestone_type === 'Second Inspection Date',
            )

            return secondInspectionMilestone === undefined && order.value.status === 'Complete'
        })

        const readFile = async (file: UppyFileResponse) => {
            isPdf.value = false
            error.value = ''

            if (!file.extension) {
                error.value = 'This file does not have an extension, please upload another file.'
                return
            }
            if (file.extension.toLowerCase() !== 'xml') {
                isPdf.value = true
                return
            }

            if (!isPdf.value) {
                const text = await file.data.text().then((result: string) => {
                    return result
                })
                const data: ValuationResponse = JSON.parse(JSON.stringify(convert.xml2js(text, { compact: true })))

                const result = AscentHelper.checkXml(data)

                if (result.error) {
                    error.value = result.message
                }
            }
        }
        const uploadFile = () => {
            if (!order.value) return
            if (!order.value.incremental) return
            if (order.value.incremental.conditions.length > 0 && order.value.status !== 'Reconsideration of Value') {
                const isValid = true

                if (!isValid) {
                    error.value = 'Please verify all conditions have been completed'
                    return false
                }
            }
            const checkXmlBeforeSubmit = (AscentHelper as AscentHelperType).featureEnabled(
                'checkXmlBeforeSubmit',
                'soft',
            )
            const length = CompletedAppraisalFileRef.value.checkFiles()
            if (length === 0) {
                error.value = 'Please add a file before proceeding'
                return false
            }
            if (checkXmlBeforeSubmit === 'soft' && error.value) {
                confirm('Please verify the report is accurate', error.value, { confirm: 'Submit' }).then((confirm) => {
                    if (confirm) {
                        loading.value = true
                        error.value = ''
                        CompletedAppraisalFileRef.value.triggerUpload()
                    }
                })
                return
            }
            if (checkXmlBeforeSubmit === 'hard' && error.value) {
                showError('Unable to submit, please correct errors')
                return
            }

            loading.value = true
            error.value = ''
            CompletedAppraisalFileRef.value.triggerUpload()
        }

        const convertToRushUW = (): void => {
            confirm('Are you sure?', 'Do you want to convert this work to a rush UW?').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    $axios.post(`/v1/order/${order.value.id}/action/convert-uw-request-to-rush`).then(() => {
                        if (!order.value) return
                        getNotes(order.value.id)
                        showSnackbar('UW Revision successfully converted to UW Rush Revision')
                    })
                }
            })
        }

        const complete = (file: File) => {
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/complete', {
                    path: file.path,
                })
                .then((response) => {
                    if (!order.value) return
                    loading.value = false
                    if (response.data.result === false) {
                        error.value = response.data.message
                        return
                    }
                    refreshOrder()
                    showSnackbar('Appraisal Completed Successfully')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        const ScheduleSecondInspectionDialog = ref({} as Dialog)
        const openSecondInspection = () => {
            ScheduleSecondInspectionDialog.value.open()
        }

        const expanded = ref(true)

        return {
            loading,
            error,
            actionLoading,
            override,
            modal,
            url,
            getAllowedFileTypes,
            stepText,
            showSecondInspectionButton,
            CompletedAppraisalFileRef,
            readFile,
            uploadFile,
            complete,
            ScheduleSecondInspectionDialog,
            openSecondInspection,
            user,
            order,
            convertToRushUW,
            expanded,
            observer,
            returnAppraiser,
            orderProducts,
        }
    },
})
