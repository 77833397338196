

























































































































import { otherContactTypeSelections } from '@/plugins/order/CustomerDefinedOrderData.ts'
import Vue from 'vue'
import { DialogPlugin } from '@syncfusion/ej2-vue-popups'
Vue.use(DialogPlugin)

import { defineComponent, reactive, ref, computed } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

import $axios from '@/plugins/axios'
import { mask } from 'vue-the-mask'
import { Dialog } from '@syncfusion/ej2-popups'
import { masks, phoneTypes } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { getNotes } from '@/plugins/Notes'
import { Contact } from '@/types'

/**
 * @name AddContactDialog
 * Dialog on order screen that allows user to add a dialog onto the order
 *
 * @StateData contact - contact object of what info will be added when contact is added
 * @SetupMethod addContact - adds contact onto the order. Refreshes contacts after contact is added.
 */

const syncDialog = ref({} as Dialog)
export const defaultContact = {
    id: null,
    name: null,
    email: null,
    phones: [{ type: null, phone: null }],
    contact_type: null,
    entry: false,
    type: null,
}
const state = reactive({
    update: false,
    adding: false,
    index: 0,
    contact: { ...defaultContact },
})
export const editContact = (contact: object, index: number) => {
    state.index = index
    state.update = true
    state.contact = JSON.parse(JSON.stringify(contact))
    if (!state.contact.phones) {
        state.contact.phones = [
            {
                type: null,
                phone: null,
            },
        ]
    }
    syncDialog.value.show()
}
export const createContact = () => {
    state.update = false
    state.contact = defaultContact
    syncDialog.value.show()
}
export default defineComponent({
    directives: { mask },
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    setup() {
        const closeDialog = () => {
            syncDialog.value.hide()
        }

        const header = computed(() => {
            if (state.update) {
                return 'Update Contact'
            } else {
                return 'Add Contact'
            }
        })

        const addContact = () => {
            state.adding = true
            if (!order.value) return
            state.contact.type = state.contact.contact_type
            $axios.post('/v1/order/' + order.value.id + '/contact', state.contact).then(({ data }) => {
                state.adding = false
                if (!order.value) return
                if (state.contact.contact_type === 'Borrower') {
                    order.value.borrowers = order.value.borrowers + ' ' + data.name
                    order.value.incremental?.borrowers.push(data)
                }

                if (state.contact.contact_type !== 'Borrower') {
                    order.value.incremental?.contacts.push(data)
                }
                getNotes(order.value.id)
                showSnackbar('Contact Added Successfully')
                syncDialog.value.hide()
            })
        }

        const updateContact = () => {
            $axios.patch('/v1/contact/' + state.contact.id, state.contact).then(({ data }) => {
                closeDialog()
                state.adding = false

                if (!order.value) return
                if (!order.value.incremental) return
                const borrowerFound = order.value.incremental.borrowers.findIndex(
                    (element: Contact) => element.id === data.id && data.contact_type == 'Borrower',
                )
                const contactFound = order.value.incremental.contacts.findIndex(
                    (element: Contact) => element.id === data.id && data.contact_type != 'Borrower',
                )

                if (borrowerFound != -1) {
                    Vue.set(order.value.incremental.borrowers, state.index, data)
                }

                if (contactFound != -1) {
                    Vue.set(
                        order.value.incremental.contacts,
                        state.index - order.value.incremental.borrowers.length,
                        data,
                    )
                }

                showSnackbar('Contact Updated Successfully')
            })
        }

        const submitFunction = () => {
            if (state.update) {
                return updateContact()
            } else {
                return addContact()
            }
        }

        return {
            state,
            syncDialog,
            addContact,
            createContact,
            editContact,
            closeDialog,
            otherContactTypeSelections,
            phoneTypes,
            masks,
            header,
            updateContact,
            submitFunction,
        }
    },
})
