










































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { Channel } from 'laravel-echo/dist/channel'
import { handleConnection } from '@/plugins/Channels'
import { redirectTo, user } from '@/plugins/User'
import { order, closeOrder } from '@/plugins/order/Order'
import { updateOrders } from '@/components/OrderTable/Classes/OrderTable'

const overlay = ref(false)
const tenancyOverlay = ref(false)
const tenancyUserType = ref<null | string>(null)

export const subscribeChangeLogin = (connector: Channel) => {
    connector.listen('.ChangedLogin', (event: { type: string }) => {
        if (event.type === 'login') {
            overlay.value = true
            return
        }

        if (event.type === 'logout') {
            overlay.value = false
        }
    })
}

export const subscribeChangeTenancy = (connector: Channel) => {
    connector.listen('.SwitchTenancy', (event: { user_id: number; user_type: string }) => {
        if (localStorage.getItem('change-tenancy-originator')) {
            localStorage.removeItem('change-tenancy-originator')
            return
        }

        tenancyOverlay.value = true
        tenancyUserType.value = event.user_type
    })
}

export default defineComponent({
    setup(_, { root }) {
        const appraiserOverlay = ref(false)

        const closeAppraiserOrder = () => {
            closeOrder()
            appraiserOverlay.value = false
        }

        const refreshScreen = () => {
            if (!tenancyOverlay.value) {
                return
            }

            const userType = tenancyUserType.value ?? 'amc'
            redirectTo(userType, true)
        }

        onMounted(() => {
            if (user.value && user.value.type == 'appraiser') {
                handleConnection({
                    channelRef: 'notifications',
                    id: user.value?.id as number,
                }).then((connector) => {
                    connector.listen('.AppraiserAssignedToOrder', (event: { orderId: number }) => {
                        if (!order.value && root.$route.name == 'Appraiser Dashboard') {
                            updateOrders()
                            return
                        }
                        if (!order.value) return
                        if (order.value.id == event.orderId) appraiserOverlay.value = true
                    })
                })
            }
        })

        return {
            appraiserOverlay,
            closeAppraiserOrder,
            overlay,
            refreshScreen,
            tenancyOverlay,
            user,
        }
    },
})
