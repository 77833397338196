



















































































import { ValidationObserver } from 'vee-validate'
import { computed, defineComponent, PropType, reactive, ref, set } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { MglMap, MglMarker } from 'vue-mapbox'
import { order } from '@/plugins/order/Order'
interface GoogleLongShort {
    long_name: string
    short_name: string
}
interface LocatedAddress {
    street: string
    sub_street: string
    city: string
    state: string
    zip: string
    latitude: number
    longitude: number
    found: boolean
    formatted: string
    all: Record<string, unknown>
    components: Record<string, GoogleLongShort>
    proximity: number
}

type AddressKeys = keyof LocatedAddress
export default defineComponent({
    props: {
        rules: {
            type: [Object, String],
            default: '',
        },
        value: {
            type: [Object] as PropType<LocatedAddress>,
            default: () => {
                return {}
            },
        },
    },
    setup(props, { emit }) {
        const loading = ref(false)
        const mapStyle = reactive({
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: { lat: 0, lng: 0 },
            zoom: 15,
            size: {
                height: 600,
            },
        })
        const error = ref(false)
        const mapboxAccessToken = process.env.VUE_APP_MAPBOX_API_KEY
        const dialog = ref(false)
        const defaultAddress: LocatedAddress = {
            street: '',
            sub_street: '',
            city: '',
            state: '',
            zip: '',
            latitude: 0,
            longitude: 0,
            found: false,
            formatted: '',
            all: {},
            components: {},
            proximity: -1,
        }
        const address = reactive({ ...defaultAddress })
        if (props.value.found) {
            for (const i in props.value) {
                set(address, i, props.value[i as AddressKeys])
            }
        }
        const processAddress = () => {
            $axios
                .post('/v1/kit/address-lookup', {
                    address: address,
                    orderId: order.value?.id,
                })
                .then(({ data }) => {
                    if (data.result) {
                        address.latitude = data.lat
                        address.longitude = data.long
                        mapStyle.center.lat = data.lat
                        mapStyle.center.lng = data.long
                        address.formatted = data.address
                        address.all = data.all
                        address.components = data.components
                        address.proximity = data.proximity
                        address.found = true
                        address.city = data.components.locality.long_name
                        address.state = data.components.administrative_area_level_1.long_name
                        return
                    }
                    error.value = true
                })
        }

        const saveAddress = () => {
            emit('input', address)
            dialog.value = false
        }

        const reset = () => {
            for (const i in defaultAddress) {
                set(address, i, defaultAddress[i as AddressKeys])
            }
        }

        const addressLines = computed(() => {
            if (!props.value.formatted) return ''
            const lines = props.value.formatted.split(', ')
            if (lines.length === 0) return ''
            return lines[0] + '<br />' + lines[1] + ', ' + lines[2]
        })

        return {
            dialog,
            processAddress,
            saveAddress,
            address,
            reset,
            mapStyle,
            mapboxAccessToken,
            loading,
            addressLines,
            error,
        }
    },
    components: {
        MglMap,
        MglMarker,
        ValidationObserver,
    },
})
