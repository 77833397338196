

























































import { defineComponent, reactive } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'

interface ConfirmOptions {
    color?: string
    width?: number
    zIndex?: number
    confirm?: string
    decline?: string
    cancel_color?: string
    x_btn?: boolean
}

const state = reactive({
    dialog: false,
    loading: false,
    message: '',
    password: '',
    resolve: undefined as undefined | ((value: boolean | PromiseLike<boolean>) => void),
    title: '',
    options: {
        color: 'primary',
        width: 500,
        zIndex: 200,
        confirm: 'Confirm',
        decline: 'Cancel',
        cancel_color: 'primary',
        x_btn: false,
    } as ConfirmOptions,
})

export const confirmPassword = (title: string, message: string, options?: ConfirmOptions): Promise<boolean> => {
    state.dialog = true
    state.title = title
    state.message = message
    state.options = Object.assign(state.options, options)
    return new Promise((resolve) => {
        state.resolve = resolve
    })
}

const agree = () => {
    if (!state.resolve) return false

    state.loading = true

    axios
        .post('/v1/user/confirm-password', { password: state.password })
        .then(() => {
            if (state.resolve) {
                state.dialog = false
                state.loading = false
                state.password = ''
                state.resolve(true)
                showSnackbar('Password confirmed successfully.')
            }
        })
        .catch((error) => {
            state.loading = false
            const message = error.response?.data?.message ?? error
            showError(message)
        })
}

const cancel = () => {
    if (!state.resolve) return false
    state.resolve(false)
    state.dialog = false
}

export default defineComponent({
    name: 'ConfirmPasswordDialog',
    components: {
        ValidationObserver,
    },
    setup() {
        return {
            state,
            confirmPassword,
            agree,
            cancel,
        }
    },
})
