























































































































import { order } from '@/plugins/order/Order'
import { defineComponent, reactive, ref, UnwrapRef } from '@vue/composition-api'
import { Note } from '@/types'
import { NoteTags as NoteTag } from '@/types'
import $axios from '@/plugins/axios'
import { Dialog } from '@/ascent'
import EditNote from '@/components/OrderScreens/Messages/EditNote.vue'
import MessageTracking from '@/components/OrderScreens/Messages/MessageTracking.vue'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import { noteTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { changeRead, getNotes, newNote, notes, NotesData } from '@/plugins/Notes'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

export const state = reactive({
    showNoteMenu: false,
    noteMenuX: 0,
    noteMenuY: 0,
    activeNote: undefined as undefined | Note,
})

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

/**
 * @name OrderNotesMenu
 * Context menu on the order notes in the amc order screen to allow user to do different actions on the notes
 *
 * @SetupMethod addTag - takes in note and tag, and adds tag to that note.
 * @SetupMethod removeTag - removes tag from note
 * @SetupMethod copyNote - copies the note to the clipboard
 * @SetupMethod openTracking - takes note and opens the message tracking for that note
 * @SetupMethod openEditNote - takes a note and opens dialog to edit the note.
 * @SetupMethod resendNote - takes note and resends failed integration note
 */

export default defineComponent({
    name: 'CustomerNoteMenu',
    components: { MessageTracking, EditNote },
    setup() {
        const changeReadStatus = (note: UnwrapRef<Note | undefined>) => {
            changeRead(note as Note).then(() => {
                state.showNoteMenu = false
                showSnackbar('Note Updated')
            })
        }

        const addTag = (note: UnwrapRef<Note | undefined>, tag: NoteTag) => {
            $axios
                .post('/v1/note/' + note?.id + '/note-tag', tag)
                .then((response) => {
                    if (note !== undefined) note.tag = response.data
                    state.showNoteMenu = false
                    showSnackbar('Tag Added Successfully')
                })
                .catch(() => {
                    showError('Oops, something went wrong!')
                })
        }
        const removeTag = (note: UnwrapRef<Note | undefined>) => {
            confirm('Remove Tag?', 'Are you sure you want to remove this tag').then((confirm) => {
                if (confirm) {
                    $axios
                        .delete('/v1/note-tag/' + note?.tag?.id)
                        .then(() => {
                            if (note !== undefined) note.tag = undefined
                            state.showNoteMenu = false
                            showSnackbar('Tag Removed Successfully')
                        })
                        .catch(() => {
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }
        const copyNote = () => {
            state.showNoteMenu = false
            showSnackbar('Note Text copied successfully!')
        }
        const MessageTracking = ref({} as Dialog)
        const openTracking = (note: UnwrapRef<Note | undefined>) => {
            MessageTracking.value.open(note)
            state.showNoteMenu = false
        }

        const EditNote = ref({} as Dialog)
        const openEditNote = (note: UnwrapRef<Note | undefined>) => {
            EditNote.value.open(note)
            state.showNoteMenu = false
        }

        const resendNote = (note: Note) => {
            confirm('Resend Note?', 'Do you want to try to resend this note?').then((confirm) => {
                if (confirm) {
                    $axios.post(`/v1/order/note/${note.id}/action/retry-failed-integration-message`).then(() => {
                        showSnackbar('Note Resent')
                        if (!order.value) return
                        getNotes(order.value.id)
                    })
                }
            })
        }

        const allowNoteMove = () =>
            state.activeNote?.source === 'Unknown' ||
            (state.activeNote?.target === 'AMC' && state.activeNote.source === 'AMC')

        const moveNote = (target: string, note: Note) => {
            state.showNoteMenu = false
            return new Promise((resolve, reject) => {
                $axios
                    .patch('/v1/note/' + note.id, {
                        target: target,
                    })
                    .then((res) => {
                        showSnackbar('Moved Note Successfully')
                        Object.assign(notes.value[notes.value.indexOf(note)], res.data)
                        resolve(true)
                    })
                    .catch(() => {
                        reject('Could Not Process Request')
                    })
            })
        }

        const createNote = (note: Note) => {
            const localNote: NotesData = {
                note: note.note,
                source: 'AMC',
                target: null,
                tag: null,
                cc: [],
                read: false,
                extra: {},
            }
            switch (note.source) {
                case 'Client':
                    localNote.target = 'appraiser'
                    break
                case 'Appraiser':
                    localNote.target = 'client'
                    break
                case 'AMC':
                    if (note.target === 'Client') localNote.target = 'appraiser'
                    if (note.target === 'Appraiser') localNote.target = 'client'
                    break
                default:
                    localNote.target = 'AMC'
            }
            newNote.value = localNote
        }

        return {
            state,
            addTag,
            removeTag,
            changeReadStatus,
            copyNote,
            MessageTracking,
            openTracking,
            noteTags,
            order,
            openEditNote,
            EditNote,
            resendNote,
            moveNote,
            allowNoteMove,
            createNote,
        }
    },
    data() {
        return {
            tags: [
                'All Tags',
                'Accounting',
                'Appraiser Options/Decline',
                'Due Date',
                'Important',
                'Manager Message',
                'Past Due Appraiser ETA',
                'Payment',
                'Client Approval',
                'Pre-Quote',
                'QC',
            ],
            currentTag: 'All Tags',
        }
    },
})
