import { AscentColumnModel } from '@/helpers/AscentDataAdapter'
import ColumnOrderId from '@/components/OrderTable/columns/ColumnOrderId.vue'
import ColumnTags from '@/components/OrderTable/columns/ColumnTags.vue'
import ColumnClientBillingTools from '@/components/OrderTable/columns/ColumnClientBillingTools.vue'
import ColumnGoogleAddress from '@/components/OrderTable/columns/ColumnGoogleAddress.vue'
import DateFilter from '@/components/OrderTable/columns/DateFilter'
import AscentHelper from '@/helpers/ascent-helper'
import { Order } from '@/types'
import { watch } from '@vue/composition-api'
import { user } from '@/plugins/User'

const OrderIdColumn = () => {
    return {
        template: ColumnOrderId,
    }
}
const TagsColumn = () => {
    return {
        template: ColumnTags,
    }
}

const ClientBillingToolsColumn = () => {
    return {
        template: ColumnClientBillingTools,
    }
}

const GoogleAddressColumn = () => {
    return {
        template: ColumnGoogleAddress,
    }
}

// To be updated as new column labels are needed
export interface CustomerColumnLabels {
    [key: string]: string
}

//Update version everytime you update columns
export const version = '1.8'

const columns: AscentColumnModel[] = [
    {
        field: 'customer_order_id',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        headerText: 'Order ID',
        textAlign: 'Left',
        template: OrderIdColumn,
        isPrimaryKey: true,
        visible: true,
        user: ['all'],
        width: 105,
        showInColumnChooser: true,
    },
    {
        field: 'customer',
        type: 'string',
        filter: { type: 'Excel' },
        allowFiltering: false,
        visible: true,
        headerText: 'Customer',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['appraiser'],
        width: 100,
        showInColumnChooser: true,
    },

    {
        field: 'paymentType',
        type: 'string',
        filter: { type: 'CheckBox' },
        allowFiltering: false,
        visible: false,
        headerText: 'Bill Type',
        textAlign: 'Left',
        user: ['amc', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'qc_priority',
        type: 'string',
        filter: { type: 'Menu' },
        allowFiltering: false,
        visible: false,
        headerText: 'Priority',
        showInColumnChooser: false,
        textAlign: 'Left',
        user: ['amc', 'desk', 'office'],
        width: 100,
    },
    {
        field: 'qc_type',
        type: 'string',
        filter: { type: 'Menu' },
        allowFiltering: false,
        visible: false,
        headerText: 'QC Type',
        showInColumnChooser: false,
        textAlign: 'Left',
        user: ['amc', 'desk', 'office'],
        width: 100,
    },
    {
        field: 'qc_assignee',
        type: 'string',
        filter: { type: 'Menu' },
        allowFiltering: false,
        visible: false,
        headerText: 'Assigned',
        showInColumnChooser: false,
        textAlign: 'Left',
        user: ['amc', 'desk', 'office'],
    },
    {
        field: 'billingTools',
        allowFiltering: false,
        filter: { type: 'Menu' },
        visible: false,
        showInColumnChooser: false,
        headerText: 'Tools',
        textAlign: 'Left',
        template: ClientBillingToolsColumn,
        allowSorting: false,
        allowGrouping: false,
        hideExcel: true,
        user: ['amc', 'desk'],
    },
    {
        field: 'assigner',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        headerText: 'Assigner',
        textAlign: 'Left',
        visible: true,
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
                fontWeight: '500',
            },
        },
        user: ['amc', 'desk', 'office'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'rep',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        headerText: 'CSS',
        textAlign: 'Left',
        visible: true,
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
                fontWeight: '500',
            },
        },
        user: ['amc', 'desk', 'appraiser', 'client', 'office'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'team',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        headerText: 'Team',
        textAlign: 'Left',
        visible: true,
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
                fontWeight: '500',
            },
        },
        user: ['amc', 'desk', 'office'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'lead',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        headerText: 'Lead',
        textAlign: 'Left',
        visible: false,
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
                fontWeight: '500',
            },
        },
        user: ['amc', 'desk'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'tags',
        type: 'string',
        allowFiltering: false,
        filter: {
            type: 'CheckBox',
        },
        visible: true,
        headerText: 'Tags',
        textAlign: 'Left',
        template: TagsColumn,
        allowSorting: false,
        allowGrouping: false,
        user: ['all'],
        width: 175,
        showInColumnChooser: true,
    },
    {
        field: 'orderStatus',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        visible: true,
        headerText: 'Status',
        textAlign: 'Left',
        customAttributes: {
            style: {
                fontWeight: '500',
            },
        },
        user: ['all'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'branchName',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Branch',
        textAlign: 'Left',
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
            },
        },
        user: ['amc', 'desk', 'client'],
        width: 150,
        showInColumnChooser: true,
    },
    {
        field: 'lenderName',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: true,
        headerText: 'Lender',
        textAlign: 'Left',
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
            },
        },
        user: ['amc', 'desk', 'office'],
        width: 150,
        showInColumnChooser: true,
    },
    {
        field: 'sublenderName',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: true,
        headerText: 'Sublender',
        textAlign: 'Left',
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
            },
        },
        user: ['office'],
        width: 150,
        showInColumnChooser: true,
    },

    {
        field: 'lenderFormName',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: true,
        headerText: 'Lender',
        textAlign: 'Left',
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
            },
        },
        user: ['appraiser'],
        width: 150,
        showInColumnChooser: true,
    },

    {
        field: 'clientName',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Ordered By',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['amc', 'desk'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'officerName',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Loan Officer',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['amc', 'desk'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'company',
        type: 'string',
        filter: { type: 'Menu' },
        allowFiltering: false,
        visible: true,
        headerText: 'Company',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['amc', 'desk'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'lenderLoanNumber',
        type: 'string',
        filter: { type: 'Menu' },
        allowFiltering: false,
        visible: true,
        headerText: 'Loan #',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },
    {
        field: 'borrowers',
        type: 'string',
        filter: { type: 'Excel' },
        allowFiltering: false,
        visible: true,
        headerText: 'Borrowers',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['all'],
        showInColumnChooser: true,
    },
    {
        field: 'addressFormatted',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: true,
        headerText: 'Property',
        textAlign: 'Left',
        template: GoogleAddressColumn,
        user: ['all'],
        width: 150,
        showInColumnChooser: true,
    },
    {
        field: 'propertyType',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        headerText: 'Property Type',
        textAlign: 'Left',
        visible: false,
        user: ['all'],
        width: 105,
        showInColumnChooser: true,
    },
    {
        field: 'appraiserName',
        type: 'string',
        filter: { type: 'Excel' },
        allowFiltering: false,
        visible: true,
        headerText: 'Appraiser',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['amc', 'desk', 'appraiser', 'office'],
        width: 100,
        showInColumnChooser: true,
    },
    {
        field: 'productList',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: true,
        headerText: 'Form Types',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },
    {
        field: 'requestedDate',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: true,
        headerText: 'Req',
        textAlign: 'Left',
        type: 'date',
        format: 'L/d/yy',
        user: ['amc', 'desk', 'client', 'office'],
        width: 90,
        showInColumnChooser: true,
    },
    {
        field: 'inspection',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: true,
        headerText: 'Insp',
        textAlign: 'Left',
        type: 'date',
        format: 'L/d/yy h:mm a',
        user: ['all'],
        width: 90,
        showInColumnChooser: true,
    },
    {
        field: 'appraiserDeadline',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: true,
        headerText: 'Appr Due',
        textAlign: 'Left',
        user: ['amc', 'desk', 'appraiser', 'office'],
        width: 90,
        type: 'date',
        formatter: (data: Record<string, string>, order: Order) => {
            return AscentHelper.formatUnix(order.appraiserDeadline, 'M/dd', false)
        },
        showInColumnChooser: true,
    },
    {
        field: 'clientDeadline',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: true,
        headerText: 'Clt Due',
        textAlign: 'Left',
        type: 'date',
        formatter: (data: Record<string, string>, order: Order) => {
            return AscentHelper.formatUnix(order.clientDeadline, 'M/dd', false)
        },
        user: ['amc', 'desk', 'client', 'office'],
        width: 90,
        showInColumnChooser: true,
    },
    {
        field: 'loan',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'CheckBox' },
        visible: false,
        headerText: 'Loan Type',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },
    {
        field: 'main',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Main Product',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },
    {
        field: 'addon',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Add On',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },

    {
        field: 'fullProduct',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Full Product',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['amc', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'loanPurpose',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'Loan Purpose',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },

    {
        field: 'borrowerAddress',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Menu' },
        visible: false,
        headerText: 'Borrower & Property',
        width: '300',
        textAlign: 'Left',
        disableHtmlEncode: false,
        user: ['amc', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'county',
        type: 'string',
        allowFiltering: false,
        filter: { type: 'Excel' },
        visible: false,
        headerText: 'County',
        textAlign: 'Left',
        user: ['all'],
        showInColumnChooser: true,
    },
    {
        field: 'paidStatus',
        type: 'string',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: false,
        headerText: 'Payment Status',
        user: ['amc', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'latestPayment',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: false,
        headerText: 'Latest Payment',
        format: 'MM/dd/yyyy',
        type: 'date',
        textAlign: 'Left',
        user: ['amc', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'fee',
        allowFiltering: false,
        filter: { type: 'Menu' },
        visible: false,
        headerText: 'Fee',
        format: 'C0',
        type: 'number',
        user: ['amc', 'office', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'paid',
        allowFiltering: false,
        filter: { type: 'Menu' },
        visible: false,
        headerText: 'Paid',
        format: 'C0',
        type: 'number',
        user: ['amc', 'office', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'due',
        allowFiltering: false,
        filter: { type: 'Menu' },
        visible: false,
        headerText: 'Due',
        format: 'C0',
        type: 'number',
        user: ['amc', 'desk'],
        showInColumnChooser: true,
    },
    {
        field: 'last_note',
        allowFiltering: false,
        allowSorting: true,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: false,
        showInColumnChooser: false,
        headerText: 'Last Note',
        textAlign: 'Left',
        type: 'date',
        format: 'L/d hh:mm a',
        user: ['amc', 'desk', 'office'],
    },
    {
        field: 'lastOpened',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: false,
        headerText: 'Last Accessed',
        textAlign: 'Left',
        type: 'date',
        format: 'L/d hh:mm a',
        user: ['amc', 'desk', 'office'],
        showInColumnChooser: true,
    },
    {
        field: 'updatedAt',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: true,
        headerText: 'Updated At',
        textAlign: 'Left',
        type: 'date',
        format: 'L/d hh:mm a',
        user: ['amc', 'desk', 'office'],
        width: 110,
        showInColumnChooser: true,
    },
    {
        field: 'clientCompleted',
        allowFiltering: false,
        filter: {
            type: 'Menu',
            ui: new DateFilter().getFilter(),
        },
        visible: false,
        headerText: 'Clt Completed',
        format: 'L/d',
        type: 'date',
        textAlign: 'Left',
        user: ['amc', 'desk', 'client', 'office'],
        showInColumnChooser: true,
    },
    {
        field: 'appraiserFee',
        allowFiltering: false,
        filter: { type: 'Menu' },
        visible: false,
        headerText: 'Appraiser Fee',
        format: 'C0',
        type: 'number',
        user: ['amc', 'desk'],
        allowSorting: false,
        showInColumnChooser: true,
    },
    {
        field: 'appraiserNames',
        type: 'string',
        allowFiltering: false,
        allowSearching: false,
        headerText: 'Order Assignees',
        textAlign: 'Left',
        visible: true,
        disableHtmlEncode: false,
        customAttributes: {
            style: {
                fontSize: '11px',
                fontWeight: '500',
            },
        },
        filter: {
            type: 'Menu',
        },
        user: ['office', 'appraiser'],
        width: 100,
        showInColumnChooser: true,
    },
]

watch(
    () => user,
    () => {
        if (user.value?.type == 'client' || user.value?.type == 'appraiser') {
            columns.map((elem) => {
                elem.allowFiltering = false
            })
        }
    },
)

export default columns
