


















































































































































































interface Instruction {
    instructions: string
}

import { computed, defineComponent, inject, onMounted, ref } from '@vue/composition-api'
import { order, snapshotOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import { Product as ProductOrder } from '@/types'
import { mask } from 'vue-the-mask'
import {
    currentReviewStep,
    conventionalFHACheck,
    otherLoanType,
    reviewNotes,
    contactNoteAdded,
    hasEntryContacts,
} from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import zillowSvg from './partials/zillowSvg.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { user } from '@/plugins/User'
import { openContacts } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactsDialog.vue'

const localOrder = ref({
    fha: null as string | null,
    purchase_price: null,
    instruction: null as Instruction | null,
})

export const isFHA = computed(() => {
    if (!order.value) return
    if (!order.value.incremental) return
    const fha = orderProducts.value.find((elem: ProductOrder) => {
        return elem.product_id === 2
    })
    return fha !== undefined
})

export const fhaNumber = computed(() => {
    if (!isFHA) return true
    if (isFHA && localOrder.value.fha === null) return false
    if (isFHA && localOrder.value.fha === '') return false
    return true
})

export default defineComponent({
    components: { zillowSvg },
    directives: { mask },
    props: {},
    setup() {
        const showInstructions = ref(false)

        const sidebarItem = inject('sidebarItem', ref(''))

        const addressUrl = computed(() => {
            if (!order.value) return
            if (order.value.address.street == null) return ''
            return (
                order.value.address.street.split(' ').join('+') +
                '+' +
                order.value.address.city +
                '+' +
                order.value.address.state +
                '+' +
                order.value.address.zip
            )
        })

        const fhaAllSame = ref(false)

        const checkFha = (fhaNumber: string) => {
            const firstIndex = fhaNumber[0]
            for (let i = 1; i < fhaNumber.length; i++) {
                if (fhaNumber[i] != firstIndex && fhaNumber[i] != '-') return false
            }
            return true
        }

        const save = (type: string) => {
            if (!order.value) return
            $axios
                .patch('/v1/order/' + order.value.id + '/edit/' + type, {
                    order: {
                        fha: localOrder.value.fha,
                        instruction: localOrder.value.instruction,
                        purchase_price: localOrder.value.purchase_price,
                    },
                })
                .then(() => {
                    if (!order.value) return
                    if (!order.value.incremental) return
                    showSnackbar('Order details saved.')
                    order.value.incremental.instruction = JSON.parse(JSON.stringify(localOrder.value.instruction))
                    order.value.fha = localOrder.value.fha
                    order.value.incremental.fha = localOrder.value.fha
                    if (type == 'fha') fhaAllSame.value = checkFha(localOrder.value.fha as string)
                })
        }

        const zillow = () => {
            if (!order.value) return
            if (order.value.address.street == null) return ''
            return (
                'https://www.zillow.com/homes/' +
                order.value.address.street.split(' ').join('-') +
                '-' +
                order.value.address.city +
                ',-' +
                order.value.address.state +
                ',-' +
                order.value.address.zip +
                '_rb'
            )
        }

        onMounted(() => {
            if (order.value?.incremental) {
                localOrder.value = JSON.parse(JSON.stringify(order.value.incremental))

                if (!localOrder.value.instruction) {
                    localOrder.value.instruction = { instructions: '' }
                }
                if (localOrder.value.fha) fhaAllSame.value = checkFha(localOrder.value.fha)
            }
        })

        return {
            addressUrl,
            contactNoteAdded,
            conventionalFHACheck,
            currentReviewStep,
            fhaAllSame,
            hasEntryContacts,
            isFHA,
            localOrder,
            order,
            snapshotOrder,
            otherLoanType,
            reviewNotes,
            save,
            showInstructions,
            sidebarItem,
            zillow,
            fhaNumber,
            user,
            openContacts,
        }
    },
})
