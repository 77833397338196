























import { defineComponent, ref } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import { appraiserValue, appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    props: {
        option: {
            type: Object || undefined,
        },
    },
    setup(props) {
        const appraiserOptionsLocal = ref({
            standard_fee: undefined,
        } as { standard_fee: string | undefined })

        if (props.option && props.option.standard_fee) {
            appraiserOptionsLocal.value.standard_fee = props.option.standard_fee
            appraiserOptions.value.standard_fee = props.option.standard_fee
        }

        const save = () => {
            if (!appraiserValue.value) return
            appraiserOptions.value.standard_fee = appraiserOptionsLocal.value.standard_fee
            axios
                .post('/v1/appraiser/' + appraiserValue.value.id + '/options', {
                    option_key: 'standard_fee',
                    option_value: appraiserOptionsLocal.value.standard_fee,
                })
                .then(() => {
                    showSnackbar('Standard Fee saved successfully!')
                })
        }

        return {
            appraiserOptionsLocal,
            save,
        }
    },
})
