
























































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, ref, computed, watch, onBeforeMount } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { Tag, Product } from '@/types'
import { GET_PANELS } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AscentHelper from '@/helpers/ascent-helper'
import $axios from '@/plugins/axios'
import ConditionalAssignDialog from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/ConditionalAssignDialog.vue'
import { DateTime } from 'luxon'
import CustomerEditOrderInvoice from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerEditOrderInvoice.vue'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { requiresDateModification, requiresFeeModification } from '@/plugins/order/OrderModification'
import { orderProducts } from '@/plugins/order/OrderProducts'
import DeadlineDatePicker, { openDatePicker } from '@/components/OrderScreens/Workflow/DeadlineDatePicker.vue'

/**
 * @name ConditionalAction
 * Workflow step for amc to accept the conditional acceptance from the appraiser with notes or reject.
 *
 * @SetupData note - note to be sent to appraiser
 * @SetupData answer - whether the condition was accepted or rejected
 * @SetupData status - status of order
 * @SetupComputed text - changes depending on what the answer was
 *
 * @SetupMethod submitAnswer - posts to v1/order/order.id/workflow/condition-(answer) with note and status. Refreshes order after status
 */

const getTotalFees = (products: Product[]) => {
    let totalAppraiserFee = 0
    let totalClientFee = 0
    let totalAmcFee = 0

    products.forEach((product) => {
        totalAppraiserFee += Number(product.appraiser_value)
        totalClientFee += Number(product.line_total)
        totalAmcFee += Number(product.amc_value)
    })
    return { totalAppraiserFee, totalClientFee, totalAmcFee }
}

export default defineComponent({
    components: {
        CustomerEditOrderInvoice,
        ConditionalAssignDialog,
        ValidationObserver,
        DeadlineDatePicker,
    },
    props: {
        activeTab: {
            type: Number,
            default: undefined,
        },
    },
    setup() {
        const note = ref('')
        const answer = ref('')
        const status = ref('Assign')
        const loading = ref(false)
        const conditionsActedUpon = ref(false)
        const text = computed(() => {
            switch (answer.value) {
                case '':
                    return 'Select Option'
                default:
                    return answer.value
            }
        })

        const products = ref<Product[]>([])
        const conditionalFee = ref(0)
        const conditionalFeeIntention = ref<'increase' | 'replace' | null>(null)
        const conditionalDate = ref('')
        const conditionalClientDate = ref('')

        const milestoneDialog = ref(false)
        const requiresModRequest = requiresFeeModification.value || requiresDateModification.value

        const setupConditionalFees = () => {
            products.value = JSON.parse(JSON.stringify(orderProducts.value))

            if (!order.value || !order.value.incremental || requiresModRequest) return

            conditionalFee.value = order.value.appraiserFee === null ? 0 : order.value.appraiserFee
            conditionalDate.value = AscentHelper.formatUnix(order.value?.appraiserDeadline, 'y-MM-dd', false)
            conditionalClientDate.value = AscentHelper.formatUnix(order.value?.clientDeadline, 'y-MM-dd', false)

            const conditionalTag = order.value.tags.find(
                (tag) => tag.tag === 'Conditional' && tag.extra && tag.extra.feeData,
            )
            if (conditionalTag && conditionalTag.extra && conditionalTag.extra.feeData) {
                conditionalFee.value = (conditionalTag.extra.feeData.fee as number) || conditionalFee.value
                conditionalDate.value = (conditionalTag.extra.feeData.due_date as string) || conditionalDate.value
                conditionalClientDate.value =
                    (conditionalTag.extra.feeData.client_due_date as string) || conditionalClientDate.value
                conditionalFeeIntention.value =
                    (conditionalTag.extra.feeData?.fee_intention as 'increase' | 'replace') || null
            }

            const { totalAppraiserFee } = getTotalFees(products.value)

            const mainProduct = products.value.find((product) => product.formType === 'Main Product')
            if (mainProduct && conditionalFeeIntention.value) {
                if (conditionalFeeIntention.value === 'increase') {
                    mainProduct.appraiser_value = Number(mainProduct.appraiser_value) + conditionalFee.value
                    mainProduct.amc_value = Number(mainProduct.amc_value) - conditionalFee.value
                } else {
                    // When replace, the increase amount is the diff to the original request from appraiser
                    const increaseAmount = Math.abs(conditionalFee.value - totalAppraiserFee)
                    mainProduct.appraiser_value = Number(mainProduct.appraiser_value) + increaseAmount
                    mainProduct.amc_value = Number(mainProduct.amc_value) - increaseAmount
                }
            }
        }

        watch(answer, () => {
            if (answer.value === 'Decline') {
                note.value = ''
                status.value = 'Assign'
                return
            }
            if (!order.value) return
            if (!order.value.tags) return
            const conditionalTag = order.value.tags.find((elem: Tag) => elem?.tag === 'Conditional')
            if (!conditionalTag?.extra) return
            const timeNow =
                conditionalTag.extra.expires && DateTime.fromISO(DateTime.now(), { zone: order.value.timezone }).ts
            if (
                conditionalTag.extra.type === 'appraiser' &&
                conditionalTag.extra.expires &&
                timeNow > new Date(conditionalTag.extra.expires as number).getTime()
            ) {
                note.value =
                    'Your conditions have been approved. Please log in and officially accept the order under the new terms.'
                status.value = 'Accept'
                return
            }
            note.value =
                'Your conditions have been approved and the order has been accepted under those terms. Please proceed with the order and reach out if you have any questions.'
            status.value = 'Schedule'
        })

        const submitAiConditions = async () => {
            if (!order.value) return
            if (answer.value !== 'Approve') return

            // Handle Date Changes
            if (conditionalDate.value !== AscentHelper.formatUnix(order.value.appraiserDeadline, 'y-MM-dd', false)) {
                await $axios
                    .post('v1/order/' + order.value.id + '/milestone', {
                        newDate: conditionalDate.value + ' 12:00 AM',
                        type: 'Appraiser Deadline',
                    })
                    .catch((err) => {
                        showError(err)
                    })
            }

            if (conditionalClientDate.value !== AscentHelper.formatUnix(order.value.clientDeadline, 'y-MM-dd', false)) {
                await $axios
                    .post('v1/order/' + order.value.id + '/milestone', {
                        newDate: conditionalClientDate.value + ' 12:00 AM',
                        type: 'Client Deadline',
                    })
                    .catch((err) => {
                        showError(err)
                    })
            }

            // Handle Fee Changes
            if (conditionalFee.value) {
                await $axios
                    .patch('/v1/order/' + order.value.id + '/action/update-products', {
                        products: products.value,
                    })
                    .catch((err) => {
                        showError(err)
                    })
            }
        }

        const submitAnswer = async (): Promise<void> => {
            if (!order.value) return
            loading.value = true
            conditionsActedUpon.value = true // This is to prevent them clicking the button again once the job has been kicked off.
            if (!requiresModRequest && answer.value === 'Approve') {
                await submitAiConditions()
            }
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/conditional-' + answer.value.toLowerCase(), {
                    note: note.value,
                    status: status.value,
                })
                .then(() => {
                    loading.value = false
                    showSnackbar('Submission successful, order will refresh once finished processing')
                })
                .catch(() => {
                    loading.value = false
                    showError('Unable to ' + answer.value.toLowerCase() + ' conditions.')
                })
        }
        const assignDialog = ref({} as InstanceType<typeof ConditionalAssignDialog>)
        const processForm = () => {
            if (!order.value || !order.value.incremental) return
            if (answer.value == 'Approve') {
                const { totalAppraiserFee, totalClientFee, totalAmcFee } = getTotalFees(products.value)
                assignDialog.value.open({
                    appraiser: order.value.incremental.appraiser,
                    appraiser_deadline: new Date(conditionalDate.value + 'T00:00:00').toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    }),
                    client_deadline: new Date(conditionalClientDate.value + 'T00:00:00').toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    }),
                    eligible: true,
                    details: '',
                    amc_fee: totalAmcFee,
                    appraiser_fee: totalAppraiserFee,
                    client_fee: totalClientFee,
                    disable_edit: true,
                })
            }
            if (answer.value == 'Decline') submitAnswer()
        }
        onBeforeMount(() => {
            GET_PANELS({
                type: 'order',
                id: order?.value?.id as number,
            })

            setupConditionalFees()
        })

        const openMilestoneDialog = (type: string, date: string, oppositeDate: string) => {
            milestoneDialog.value = true
            openDatePicker(type, Date.parse(date), Date.parse(oppositeDate))
        }

        const saveDate = ({ date, type }: { date: string; type: string }) => {
            if (type === 'Client Deadline') {
                conditionalClientDate.value = date
            } else {
                conditionalDate.value = date
            }
            milestoneDialog.value = false
        }

        return {
            note,
            answer,
            text,
            status,
            assignDialog,
            processForm,
            submitAnswer,
            order,
            loading,
            conditionalFee,
            conditionalDate,
            conditionalClientDate,
            openMilestoneDialog,
            milestoneDialog,
            requiresModRequest,
            products,
            saveDate,
            conditionsActedUpon,
        }
    },
})
