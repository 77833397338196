
































import { defineComponent, PropType, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { LenderOptions } from '@/types'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const value = ref(false)

        if (props && props.option) {
            value.value = props.option.option_value as boolean
        }

        const saveOption = () => {
            $axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'qc-billing-override',
                    option_value: value.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            value,
            saveOption,
        }
    },
})
