


























import axios from 'axios'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { AutoForwardNotesOption, LenderOptions } from '@/types'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref({ enabled: false } as AutoForwardNotesOption)
        const save = () => {
            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'auto-forward-notes',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
                .catch(() => {
                    showError('Unable to update setting')
                })
        }

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as AutoForwardNotesOption
        }

        return {
            save,
            optionLocal,
        }
    },
})
