























































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from 'axios'
import { License } from '@/types'
import AEditor from '@/global/Form/AEditor.vue'

/**
 * @name AssignOverride
 * Workflow step for those who can handle overrides. Overrides and assigns order to appraiser if approval is needed
 *
 * @SetupData licenseStatus - status of the license
 * @SetupData insuranceStatus - status of the insurance
 *
 * @onBeforeMount makes 2 axios calls to get the status of the license and the insurance
 */

export default defineComponent({
    components: { AEditor },
    setup() {
        const licenseStatus = ref('')
        const insuranceStatus = ref('')
        const holdReason = ref('')
        const note = ref('')
        const loading = ref(false)
        onBeforeMount(() => {
            if (!order.value) return
            $axios.get('/v1/appraiser/' + order.value.appraiser_id + '/license/').then((response) => {
                if (!response.data) return
                licenseStatus.value = response.data.find(
                    (el: License) => el.state === order.value?.address?.state,
                )?.status
            })
            $axios.get('/v1/appraiser/' + order.value.appraiser_id + '/insurance/').then((response) => {
                if (!response.data) return
                insuranceStatus.value = response.data.find(
                    (el: License) => el.state === order.value?.address?.state,
                )?.status
            })

            $axios.get(`/v1/appraiser/${order.value.appraiser_id}/termination-hold`).then((response) => {
                if (response.data.type == 'Hold') holdReason.value = response.data.reason
            })
        })

        const approveOrder = () => {
            if (!order.value) return
            if (!order.value.incremental) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/override-approve/', {
                    approval_id: order.value.incremental.assign_override_approval.id,
                    reason: note.value,
                })
                .then(async () => {
                    if (!order.value) return
                    loading.value = false
                    await refreshOrder()
                    await getNotes(order.value.id)
                    showSnackbar('Approved Successfully!')
                })
                .catch(({ response }) => {
                    loading.value = false
                    if (response.status == 422) showSnackbar(response.data.errors.reason, 'error')
                })
        }

        return {
            order,
            user,
            licenseStatus,
            insuranceStatus,
            holdReason,
            note,
            loading,
            approveOrder,
        }
    },
    methods: {
        /**
         * Approves the order and allows appraiser to be assigned. Refreshed order after
         */

        /**
         * Declines the approval with reason and updates the order after
         */
        declineOrder() {
            if (!order.value) return
            if (!order.value.incremental) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/override-reject/', {
                    approval_id: order.value.incremental.assign_override_approval.id,
                    reason: this.note,
                })
                .then(async () => {
                    if (!order.value) return
                    await refreshOrder()
                    await getNotes(order.value.id)
                    showSnackbar('Rejected Successfully!')
                })
                .catch((e) => {
                    showError(`Error fetching data. (${e})`)
                })
        },
    },
})
