var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$ascent.isCustomer())?_c('v-menu',{attrs:{"bottom":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-sm-1 d-none d-sm-inline-block",attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$vuetify.icons.ellipsis")])],1)]}}],null,false,338262965)},[_c('v-list',{attrs:{"dense":""}},[(_vm.order.status !== 'Cancelled')?_c('span',[_c('v-list-item',{on:{"click":_vm.EditOrderDialog.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Order")])],1)],1),(_vm.order.order_type !== 'review')?_c('v-list-item',{on:{"click":_vm.openEditInstructions}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Order Instructions")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.CancelOrderDialog.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancel Order")])],1)],1),(_vm.isUserRep && _vm.canEscalate && _vm.order.order_type !== 'review')?_c('v-list-item',{on:{"click":_vm.openOrderEscalation}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Escalate Order")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openAuditLog('order', _vm.order.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Order Log")])],1)],1),(
                        _vm.order.order_type !== 'review' &&
                        !['Hold', 'Payment Hold', 'Pending Cancellation', 'Cancelled'].includes(_vm.order.status) &&
                        _vm.hasPaymentHoldTag === false
                    )?_c('v-list-item',{on:{"click":function($event){return _vm.PlaceOrderOnHold(false)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Place Hold")])],1)],1):_vm._e(),(_vm.order.status === 'Assign' && _vm.order.order_type !== 'review')?_c('v-list-item',{on:{"click":function($event){return _vm.PlaceOrderOnHold(true)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Place Payment Hold")])],1)],1):_vm._e(),(
                        (_vm.order.order_type !== 'review' && _vm.order.status === 'Hold') ||
                        ((_vm.order.status === 'Payment Hold' || _vm.hasPaymentHoldTag === true) &&
                            !['Sales', 'Specialist', 'Quality', 'Assignment Coordinator', 'External'].includes(
                                _vm.role
                            ))
                    )?_c('v-list-item',{on:{"click":function($event){_vm.removeHoldDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Remove Hold")])],1)],1):_vm._e(),(_vm.isCompleted && _vm.order.order_type !== 'review')?_c('v-list-item',{on:{"click":_vm.ResendClientFiles}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Resend Client Files")])],1)],1):_vm._e(),(_vm.order.order_type !== 'review' && _vm.isCompleted && _vm.lenderHasUCDP && !_vm.lenderHasUcdpHold)?_c('v-list-item',{on:{"click":_vm.VerosSubmissionDialog.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New UCDP/EAD Submission")])],1)],1):_vm._e(),(
                        _vm.order.order_type !== 'review' &&
                        (_vm.order.status === 'QC Review' || _vm.order.status === 'QC Revision')
                    )?_c('span',[_c('v-list-item',{on:{"click":_vm.UploadNewReportDialog.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Upload New Report")])],1)],1),(_vm.order.order_type !== 'review')?_c('v-list-item',{on:{"click":_vm.ReleaseDraftReport}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Release Draft Report")])],1)],1):_vm._e(),(_vm.isCompleted && _vm.order.order_type !== 'review')?_c('v-list-item',{on:{"click":_vm.EscalateOrderDialog.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Escalate Order")])],1)],1):_vm._e()],1):_vm._e(),(
                        _vm.lenderCanManuallySendBorrowerDelivery &&
                        _vm.isCompleted &&
                        _vm.order.order_type !== 'review' &&
                        _vm.order.incremental &&
                        !_vm.order.incremental.hasCurrentBorrowerDeliveryBeenSent
                    )?_c('v-list-item',{on:{"click":_vm.SendBorrowerDelivery}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Send Borrower Delivery")])],1)],1):_vm._e(),(
                        _vm.lenderCanResendBorrowerDelivery &&
                        _vm.isCompleted &&
                        _vm.order.order_type !== 'review' &&
                        _vm.order.incremental &&
                        _vm.order.incremental.hasCurrentBorrowerDeliveryBeenSent
                    )?_c('v-list-item',{on:{"click":_vm.ResendBorrowerDelivery}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Resend Borrower Delivery")])],1)],1):_vm._e()],1):_vm._e(),(_vm.order.order_type !== 'review')?_c('OrderAdditionalProduct'):_vm._e(),(
                    (_vm.order.status === 'Cancelled' || _vm.order.status === 'Pending Cancellation') &&
                    (_vm.order.source === 'form' ||
                        _vm.order.source === 'mercury' ||
                        _vm.order.source === 'encompass' ||
                        _vm.order.source === 'guild' ||
                        _vm.order.source === 'shield' ||
                        _vm.order.source === 'lenderx' ||
                        _vm.order.source === 'closing')
                )?_c('v-list-item',{on:{"click":function($event){_vm.openRevertDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Re-Open Order")])],1)],1):_vm._e(),(_vm.user && _vm.user.ascent)?_c('div',[_c('v-subheader',[_vm._v("Ascent")]),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.refreshOrder()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.sync")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Refresh")])],1)],1),_c('v-list-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.order.id),expression:"order.id",arg:"copy"}],on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.clipboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.order.id))])],1)],1),_c('v-list-item',{attrs:{"href":'https://support.jarodesk.com/order/' + _vm.order.id + '/log',"target":"_blank"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.externalLinkSquare")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Order Log")])],1)],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.user.type === 'client' && _vm.directOrder)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-sm-1 d-none d-sm-inline-block",attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$vuetify.icons.ellipsis")])],1)]}}],null,false,338262965)},[_c('v-list',{attrs:{"dense":""}},[(!_vm.isCompleted && _vm.lenderCanChangeOrderStatus)?_c('v-list-item',{on:{"click":_vm.CancelOrderDialog.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancel Order")])],1)],1):_vm._e(),(
                    _vm.order.order_type !== 'review' &&
                    !['Hold', 'Payment Hold', 'Pending Cancellation', 'Cancelled'].includes(_vm.order.status) &&
                    !_vm.isCompleted &&
                    _vm.hasPaymentHoldTag === false &&
                    _vm.lenderCanChangeOrderStatus
                )?_c('v-list-item',{on:{"click":function($event){return _vm.PlaceOrderOnHold(false)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Place Hold")])],1)],1):_vm._e(),(_vm.order.order_type !== 'review' && _vm.order.status === 'Hold' && _vm.lenderCanChangeOrderStatus)?_c('v-list-item',{on:{"click":function($event){_vm.removeHoldDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Remove Hold")])],1)],1):_vm._e(),(
                    _vm.lenderCanManuallySendBorrowerDelivery &&
                    _vm.isCompleted &&
                    _vm.order.order_type !== 'review' &&
                    _vm.order.incremental &&
                    !_vm.order.incremental.hasCurrentBorrowerDeliveryBeenSent
                )?_c('v-list-item',{on:{"click":_vm.SendBorrowerDelivery}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Send Borrower Delivery")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('CancelOrderDialog',{ref:"CancelOrderDialog"}),_c('EditOrderDialog',{ref:"EditOrderDialog"}),_c('EscalateOrderDialog',{ref:"EscalateOrderDialog"}),_c('UploadNewReportDialog',{ref:"UploadNewReportDialog"}),_c('VerosSubmissionDialog',{ref:"VerosSubmissionDialog"}),_c('EditOrderInstructionsDialog'),_c('OrderRemoveHoldDialog'),_c('OrderHoldDialog'),_c('EscalateOrdersDialog'),_c('CancelEscalationDialog'),_c('RevertCancelledOrderDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }