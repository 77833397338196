import { computed, Ref, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { AppraiserProfile, County, Rosters, TagConfig } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import { user } from '@/plugins/User'

export interface AppraiserOptionsLocal {
    experience: string
    max_orders: number
    stagger: number
    tags: string[]
    assign_notes: string
    tier?: string
    id: number
}

interface Status {
    contact: boolean
    license: boolean
    insurance: boolean
    w9: boolean
    workarea: boolean
}

export const statuses = ref({
    contact: true,
    license: true,
    insurance: true,
    w9: true,
    workarea: true,
} as Status)
export const unlinkedRoster = ref({} as Rosters)

export interface AppraiserOptionsSettings {
    status: string
    click_fees_enabled: boolean | undefined
    external_id: string
    ssrs_disabled: boolean
    require_max_approval: boolean
    experience: string
    billing: string
    max_orders: number
    stagger: number
    tags: string[]
    assign_notes: string
    allow_card_refund: boolean
    tier: string
    id: number
    reviews: {
        disable_automated_reviews: boolean
        disable_external_reviews: boolean
    }
    appraiser_roles: {
        is_trainee: boolean
        reviews: boolean
        preload: boolean
        data_analyst: boolean
        counties: number[] | string[] | County[]
        inspection: boolean
        a_z: boolean
        full: boolean
        supervisor: boolean
        secondary_supervisors: number[]
        primary_supervisor: number[]
        report_days: null
        inspection_days: number
        stateValue: string[]
        stateReviews: string[]
        countiesReviews: number[] | string[] | County[]
    }
    qc_access: {
        qc_override: boolean
        qc_permissions: boolean
    }
    scheduler: number
    jarokit_beta: boolean
    standard_fee: string | undefined
}

export interface AppraiserPersonalSettings {
    experience: string
    languages: string[]
}

export interface AppraiserSettings {
    label: string
    type: string
    options?: {}
}

// Mutations

const setStatuses = (selectedStatus: Status): void => {
    statuses.value = selectedStatus
}

const setUnlinkedRoster = (roster: Rosters): void => {
    unlinkedRoster.value = roster
}

export const removeUnlinkedRoster = (): void => {
    unlinkedRoster.value = {
        id: 0,
        state: '',
        license: 0,
        address_state: '',
        first: '',
        last: '',
        middle: '',
        street: '',
        zip: '',
        city: '',
        company: '',
        phone: '',
        effective: '',
        expire: '',
    }
}

const RESET = (): void => {
    statuses.value = {
        contact: true,
        license: true,
        insurance: true,
        w9: true,
        workarea: true,
    }
    unlinkedRoster.value = {
        id: 0,
        state: '',
        license: 0,
        address_state: '',
        first: '',
        last: '',
        middle: '',
        street: '',
        zip: '',
        city: '',
        company: '',
        phone: '',
        expire: '',
        effective: '',
    }
}

export const getUnlinkedRoster = (id: string) => {
    return new Promise((resolve) => {
        $axios
            .get('/v1/appraiser/' + id + '/action/get-unlinked-roster')
            .then((response) => {
                if (!response) return
                setUnlinkedRoster(response.data)
                resolve(true)
            })
            .catch()
    })
}

export const checkStatuses = (id: string) => {
    return new Promise((resolve) => {
        if (id) {
            $axios.get('/v1/appraiser/' + id + '/action/get-statuses').then((response) => {
                RESET()
                setStatuses(response.data)
                resolve(true)
            })
        }
    })
}

export const appraiserSettings = ref()
export const appraiserValue: Ref<AppraiserProfile | undefined> = ref(undefined)
export const amcStatus = ref()
export const appraiserSettingsOptions = ref()
export const appraiserSettingsId = ref()
export const appraiserTagsSettings = ref([] as TagConfig[])
export const appraiserPersonalSettings = ref({} as AppraiserPersonalSettings)

export const isUserVendor = computed(() => {
    if (!user.value) return false
    if (!appraiserValue.value) return false
    return appraiserValue.value.user_id === user.value.id
})

export const canEdit = computed(() => {
    if (!user.value || !appraiserValue.value) return false
    if (user.value.type == 'appraiser' || user.value.ascent) return true
    // if (user.value.customer_id != appraiserValue.value.customer_id) return false
    return AscentHelper.canDo('Edit Appraiser')
})
export const getAppraiserTags = () => {
    $axios.get('/v1/settings/tags').then(({ data }) => {
        if (data.result.length > 0) {
            appraiserTagsSettings.value = data.result.find((e: TagConfig) => e.type === 'Appraiser Tags').tags
        }
    })
}
export const getAppraiserSettings = () => {
    $axios.get('/v1/settings/appraiser-settings').then((response) => {
        getAppraiserTags()
        appraiserSettings.value = response.data.result
        response.data.result.map((elem: AppraiserSettings) => {
            if (elem.type === 'settings-page') appraiserSettingsOptions.value = elem.options
        })
    })
}

export const getAppraiserPersonalSettings = (id: string | number) => {
    $axios.get('/v1/appraiser/' + id + '/settings').then((response) => {
        appraiserPersonalSettings.value = response.data.settings
    })
}

export const appraiserOptions = ref<AppraiserOptionsSettings>({
    status: '',
    click_fees_enabled: undefined,
    external_id: '',
    ssrs_disabled: false,
    require_max_approval: false,
    experience: '',
    max_orders: 0,
    stagger: 0,
    tags: [],
    billing: 'Proceed',
    assign_notes: '',
    tier: '',
    allow_card_refund: false,
    id: 0,
    reviews: {
        disable_automated_reviews: false,
        disable_external_reviews: false,
    },
    appraiser_roles: {
        is_trainee: false,
        preload: false,
        data_analyst: false,
        counties: [],
        inspection: false,
        a_z: false,
        supervisor: false,
        reviews: false,
        inspection_days: 0,
        full: false,
        report_days: null,
        secondary_supervisors: [],
        primary_supervisor: [],
        stateValue: [],
        stateReviews: [],
        countiesReviews: [],
    },
    qc_access: {
        qc_override: false,
        qc_permissions: false,
    },
    scheduler: 0,
    jarokit_beta: false,
    standard_fee: '',
})
