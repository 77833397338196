






































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import FileUpload from '@/components/General/FileUpload.vue'
import convert from 'xml-js'
import ConditionList from '@/components/OrderScreens/Customer/CustomerOrderComponents/ConditionList.vue'
import $axios from '@/plugins/axios'
import { ProductForm, File, UppyFileResponse } from '@/types'
import { ElementCompact } from 'xml-js'
import { enabledCustomerFeatures, user } from '@/plugins/User'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { orderProducts } from '@/plugins/order/OrderProducts'
import AscentHelper from '@/helpers/ascent-helper'
import { get } from 'lodash'
import CancelInspectionButton from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/CancelInspectionButton.vue'

interface ValuationResponse {
    [x: string]: ElementCompact
}

export default defineComponent({
    components: {
        ConditionList,
        ValidationObserver,
        FileUpload,
        CancelInspectionButton,
    },
    setup() {
        const loading = ref(false)
        const error = ref('')
        const actionLoading = ref(false)
        const override = ref(false)
        const modal = ref(false)
        const observer = ref({})
        const isPdf = ref(false)
        const expanded = ref(true)
        const cond_expanded = ref(false)

        const CompletedAppraisalFileRef = ref(
            {} as Vue & { checkFiles: () => number } & { triggerUpload: () => void } & { resetFiles: () => void },
        )

        const url = computed(() => {
            if (!order.value) return
            return 'order/' + order.value.id
        })

        const mainProduct = computed(() => {
            if (!order.value) return
            if (!order.value?.incremental) return
            const mainProduct = orderProducts.value.filter((elem: ProductForm) => elem.form.type === 'Main Product')
            return mainProduct[0]
        })

        const getAllowedFileTypes = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return
            if (!AscentHelper.customerFeatureEnabled('orderScreen.workflow.completed.requireXml', true))
                return ['.pdf', '.xml']
            if (!mainProduct.value) return
            if (override.value) return ['.pdf', '.xml']
            if (!orderProducts.value.filter((elem: ProductForm) => elem.form.type === 'Main Product')) return ['.pdf']
            if (
                mainProduct?.value?.form?.detail?.details?.xml === false ||
                mainProduct?.value?.form?.detail?.details?.xml === 0
            ) {
                return ['.pdf']
            }
            return ['.xml']
        })

        const deleteFile = () => {
            error.value = ''
            CompletedAppraisalFileRef.value.resetFiles()
        }
        const readFile = async (file: UppyFileResponse) => {
            isPdf.value = false
            error.value = ''

            if (!file.extension) {
                error.value = 'This file does not have an extension, please upload another file.'
                return
            }
            if (file.extension.toLowerCase() !== 'xml') {
                isPdf.value = true
                return
            }

            if (!isPdf.value) {
                const text = await file.data.text().then((result: string) => {
                    return result
                })
                const data: ValuationResponse = JSON.parse(JSON.stringify(convert.xml2js(text, { compact: true })))

                const result = AscentHelper.checkXml(data)

                if (result.error) {
                    error.value = result.message
                }
            }
        }
        const uploadFile = () => {
            if (!order.value) return
            if (!order.value.incremental) return
            if (order.value.incremental.conditions.length > 0 && order.value.status !== 'Reconsideration of Value') {
                const isValid = true

                if (!isValid) {
                    error.value = 'Please verify all conditions have been completed'
                    return false
                }
            }

            const checkXmlBeforeSubmit = get(enabledCustomerFeatures.value, 'checkXmlBeforeSubmit', 'soft')

            const length = CompletedAppraisalFileRef.value.checkFiles()
            if (length === 0) {
                error.value = 'Please add a file before proceeding'
                return false
            }
            if (checkXmlBeforeSubmit === 'soft' && error.value) {
                confirm('Please verify the report is accurate', error.value, { confirm: 'Submit' }).then((confirm) => {
                    if (confirm) {
                        loading.value = true
                        error.value = ''
                        CompletedAppraisalFileRef.value.triggerUpload()
                    }
                })
                return
            }
            if (checkXmlBeforeSubmit === 'hard' && error.value) {
                showError('Unable to submit, please correct errors')
                return
            }

            loading.value = true
            error.value = ''
            CompletedAppraisalFileRef.value.triggerUpload()
        }

        const complete = (file: File) => {
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/complete', {
                    path: file.path,
                })
                .then((response) => {
                    if (!order.value) return
                    loading.value = false
                    if (response.data.result === false) {
                        error.value = response.data.message
                        return
                    }
                    refreshOrder()
                    showSnackbar('Appraisal Completed Successfully')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        return {
            loading,
            error,
            actionLoading,
            override,
            modal,
            url,
            getAllowedFileTypes,
            CompletedAppraisalFileRef,
            deleteFile,
            readFile,
            uploadFile,
            complete,
            user,
            order,
            expanded,
            observer,
            orderProducts,
            cond_expanded,
        }
    },
})
