import { ref } from '@vue/composition-api'
import { AppraiserOptionPanel, JaroPanelData, JaroPanelExpandedHeader, JaroPanelRule } from '@/types'
import axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'

interface GetValidJaroPanelsPayload {
    order_id?: number
    customer_id?: number
    rules?: JaroPanelRule[]
    viewer?: boolean
    panel_type?: string
    panel_profile_type?: string[]
    panel_tags?: string[]
    zip?: string | number
    county_id?: string | number
    lender_id?: string | number
    branch_id?: string | number
    override_closed_panel?: boolean
}

export const jaroPanels = ref([] as JaroPanelData[])
export const requiresClosedPanel = ref(false)
export const distinctAppraiserLicenseTypes = ref([])

export const getAppraiserOption = <T extends keyof AppraiserOptionPanel>(
    options: AppraiserOptionPanel[] = [],
    key: T,
    notFound: string,
    prepend = '',
) => {
    const result = options?.find((item) => Object.prototype.hasOwnProperty.call(item, key))
    if (result && result[key]) {
        if (prepend) {
            return (prepend + result[key]) as string
        }
        return result[key] as string
    }
    return notFound ?? ''
}

export const jaroSavedPanelColumns = ref(
    JSON.parse(localStorage.getItem('jaro-assign-panel-columns') ?? '[]') as JaroPanelExpandedHeader[],
)

export const getValidJaroPanels = async (payload: GetValidJaroPanelsPayload) => {
    jaroPanels.value = []
    distinctAppraiserLicenseTypes.value = []

    if (order.value && order.value.branch_id && !payload.override_closed_panel) {
        await axios
            .get('/v1/branch/' + order.value.branch_id + '/option/closed-panel')
            .then((response) => {
                if (response.data && response.data.option_value) {
                    requiresClosedPanel.value = response.data.option_value
                }
            })
            .catch(() => {
                return
            })
    }

    if (payload.override_closed_panel) {
        requiresClosedPanel.value = false
    }

    await axios.post('/v1/search/jaroPanel', payload).then((response) => {
        jaroPanels.value = (response.data.result as JaroPanelData[]) ?? []
        distinctAppraiserLicenseTypes.value = response.data.distinctAppraiserLicenseTypes ?? []
    })
}
