import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import { VuetifyIcons } from 'vuetify/types/services/icons'
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { UserVuetifyPreset } from 'vuetify/types/services/presets'

library.add(fal)
library.add(fas)
library.add(fab)
library.add(fad)

const makeIconComponentDeclaration = (icon: IconDefinition) => ({
    component: 'a-icon',
    props: {
        icon: [icon.prefix, icon.iconName],
    },
})

export const Icons: Partial<VuetifyIcons> = {
    abacus: makeIconComponentDeclaration(fal['faAbacus']),
    address: makeIconComponentDeclaration(fal['faMapMarkedAlt']),
    addressCard: makeIconComponentDeclaration(fal['faAddressCard']),
    addUser: makeIconComponentDeclaration(fal['faUserPlus']),
    alarm: makeIconComponentDeclaration(fas['faAlarmExclamation']),
    alarmAlt: makeIconComponentDeclaration(fal['faAlarmExclamation']),
    alarmSnooze: makeIconComponentDeclaration(fal['faAlarmSnooze']),
    angleDown: makeIconComponentDeclaration(fal['faAngleDown']),
    angleLeft: makeIconComponentDeclaration(fal['faAngleLeft']),
    angleRight: makeIconComponentDeclaration(fal['faAngleRight']),
    angleUp: makeIconComponentDeclaration(fal['faAngleUp']),
    arrowCircleLeft: makeIconComponentDeclaration(fal['faArrowCircleLeft']),
    arrowCircleRight: makeIconComponentDeclaration(fal['faArrowCircleRight']),
    arrowCircleUp: makeIconComponentDeclaration(fal['faArrowAltCircleUp']),
    arrowDown: makeIconComponentDeclaration(fas['faArrowDown']),
    arrowLeft: makeIconComponentDeclaration(fal['faArrowLeft']),
    arrowRight: makeIconComponentDeclaration(fal['faArrowRight']),
    arrowsSpinLight: makeIconComponentDeclaration(fal['faArrowsSpin']),
    arrowsSpinSolid: makeIconComponentDeclaration(fas['faArrowsSpin']),
    arrowsV: makeIconComponentDeclaration(fas['faArrowsV']),
    arrowTurnDownRight: makeIconComponentDeclaration(fal['faArrowTurnDownRight']),
    arrowUp: makeIconComponentDeclaration(fas['faArrowUp']),
    arrowUpRightFromSquare: makeIconComponentDeclaration(fas['faArrowUpRightFromSquare']),
    arrowUpRightFromSquareAlt: makeIconComponentDeclaration(fal['faArrowUpRightFromSquare']),
    balanceScale: makeIconComponentDeclaration(fal['faBalanceScale']),
    ballotCheck: makeIconComponentDeclaration(fal['faBallotCheck']),
    ban: makeIconComponentDeclaration(fal['faBan']),
    bars: makeIconComponentDeclaration(fal['faBars']),
    barsFilter: makeIconComponentDeclaration(fas['faBarsFilter']),
    bath: makeIconComponentDeclaration(fal['faBath']),
    bed: makeIconComponentDeclaration(fal['faBed']),
    bedAlt: makeIconComponentDeclaration(fal['faBedAlt']),
    bell: makeIconComponentDeclaration(fal['faBell']),
    bomb: makeIconComponentDeclaration(fas['faBomb']),
    book: makeIconComponentDeclaration(fas['faBookOpen']),
    bug: makeIconComponentDeclaration(fas['faBug']),
    building: makeIconComponentDeclaration(fal['faBuilding']),
    buildingLight: makeIconComponentDeclaration(fal['faBuilding']),
    buildings: makeIconComponentDeclaration(fal['faBuildings']),
    calculator: makeIconComponentDeclaration(fal['faCalculator']),
    'calendar-exclamation': makeIconComponentDeclaration(fal['faCalendarExclamation']),
    calendar: makeIconComponentDeclaration(fal['faCalendar']),
    calendarAlt: makeIconComponentDeclaration(fal['faCalendarAlt']),
    calendarClock: makeIconComponentDeclaration(fal['faClock']),
    calendarWeek: makeIconComponentDeclaration(fal['faCalendarWeek']),
    cancel: makeIconComponentDeclaration(fas['faTimesCircle']),
    cartShopping: makeIconComponentDeclaration(fal['faCartShopping']),
    ccAmex: makeIconComponentDeclaration(fab['faCcAmex']),
    ccDiscover: makeIconComponentDeclaration(fab['faCcDiscover']),
    ccMastercard: makeIconComponentDeclaration(fab['faCcMastercard']),
    ccVisa: makeIconComponentDeclaration(fab['faCcVisa']),
    certificate: makeIconComponentDeclaration(fal['faCertificate']),
    chartPie: makeIconComponentDeclaration(fal['faChartPie']),
    chartTreeMap: makeIconComponentDeclaration(fal['faChartTreeMap']),
    check: makeIconComponentDeclaration(fal['faCheck']),
    checkboxIndeterminate: makeIconComponentDeclaration(fal['faMinusSquare']),
    checkboxOff: makeIconComponentDeclaration(fal['faSquare']), // note 'far'
    checkboxOn: makeIconComponentDeclaration(fas['faCheckSquare']),
    checkCircle: makeIconComponentDeclaration(fal['faCheckCircle']),
    checkCircleFilled: makeIconComponentDeclaration(fas['faCheckCircle']),
    checkDouble: makeIconComponentDeclaration(fas['faCheckDouble']),
    chessQueen: makeIconComponentDeclaration(fas['faChessQueen']),
    chevronDoubleLeft: makeIconComponentDeclaration(fal['faChevronDoubleLeft']),
    chevronDoubleRight: makeIconComponentDeclaration(fal['faChevronDoubleRight']),
    chevronDown: makeIconComponentDeclaration(fal['faChevronDown']),
    chevronUp: makeIconComponentDeclaration(fal['faChevronUp']),
    chrome: makeIconComponentDeclaration(fab['faChrome']),
    'circle-exclamation': makeIconComponentDeclaration(fal['faCircleExclamation']),
    circle: makeIconComponentDeclaration(fas['faCircle']),
    circleDollar: makeIconComponentDeclaration(fal['faCircleDollar']),
    circleQuestion: makeIconComponentDeclaration(fas['faCircleQuestion']),
    circleUser: makeIconComponentDeclaration(fal['faCircleUser']),
    clear: makeIconComponentDeclaration(fas['faTimesCircle']),
    clipboard: makeIconComponentDeclaration(fal['faClipboard']),
    clipboardCheck: makeIconComponentDeclaration(fal['faClipboardCheck']),
    clipboardList: makeIconComponentDeclaration(fal['faClipboardList']),
    clipboardListCheck: makeIconComponentDeclaration(fal['faClipboardListCheck']),
    clipboardUser: makeIconComponentDeclaration(fas['faClipboardUser']),
    clock: makeIconComponentDeclaration(fal['faClock']),
    clockAlt: makeIconComponentDeclaration(fas['faClock']),
    clockRotateLeft: makeIconComponentDeclaration(fal['faClockRotateLeft']),
    clone: makeIconComponentDeclaration(fal['faClone']),
    close: makeIconComponentDeclaration(fal['faTimes']),
    cogs: makeIconComponentDeclaration(fal['faCogs']),
    collapse: makeIconComponentDeclaration(fas['faAngleUp']),
    columns: makeIconComponentDeclaration(fal['faColumns']),
    columnsSolid: makeIconComponentDeclaration(fas['faColumns']),
    comment: makeIconComponentDeclaration(fal['faComment']),
    commentAlt: makeIconComponentDeclaration(fas['faCommentAltLines']),
    commentAltLines: makeIconComponentDeclaration(fal['faCommentAltLines']),
    commentCheck: makeIconComponentDeclaration(fal['faCommentCheck']),
    commentPlus: makeIconComponentDeclaration(fal['faCommentPlus']),
    complete: makeIconComponentDeclaration(fal['faCheck']),
    copy: makeIconComponentDeclaration(fal['faCopy']),
    creditCard: makeIconComponentDeclaration(fal['faCreditCard']),
    creditCardFront: makeIconComponentDeclaration(fal['faCreditCardFront']),
    delete: makeIconComponentDeclaration(fas['faTimesCircle']), // delete (e.g. v-chip close)
    delimiter: makeIconComponentDeclaration(fas['faCircle']), // for carousel
    deliveryTruck: makeIconComponentDeclaration(fas['faTruck']),
    diagramSankey: makeIconComponentDeclaration(fal['faDiagramSankey']),
    dollarSign: makeIconComponentDeclaration(fal['faDollarSign']),
    download: makeIconComponentDeclaration(fal['faDownload']),
    dropdown: makeIconComponentDeclaration(fas['faCaretDown']),
    edge: makeIconComponentDeclaration(fab['faEdge']),
    edit: makeIconComponentDeclaration(fal['faEdit']),
    editAlt: makeIconComponentDeclaration(fas['faEdit']),
    ellipsis: makeIconComponentDeclaration(fal['faEllipsisV']),
    envelope: makeIconComponentDeclaration(fal['faEnvelope']),
    envelopeAlt: makeIconComponentDeclaration(fas['faEnvelope']),
    envelopeDot: makeIconComponentDeclaration(fad['faEnvelopeDot']),
    envelopeOpen: makeIconComponentDeclaration(fal['faEnvelopeOpen']),
    envelopeOpenText: makeIconComponentDeclaration(fal['faEnvelopeOpenText']),
    error: makeIconComponentDeclaration(fas['faExclamationTriangle']),
    exclamation: makeIconComponentDeclaration(fal['faExclamation']),
    exclamationCircle: makeIconComponentDeclaration(fal['faExclamationCircle']),
    exclamationTriangle: makeIconComponentDeclaration(fal['faExclamationTriangle']),
    expand: makeIconComponentDeclaration(fal['faAngleDown']),
    expandArrows: makeIconComponentDeclaration(fal['faExpandArrows']),
    externalLinkAlt: makeIconComponentDeclaration(fal['faExternalLinkAlt']),
    externalLinkSquare: makeIconComponentDeclaration(fal['faExternalLinkSquare']),
    faceFrown: makeIconComponentDeclaration(fal['faFaceFrown']),
    faceMeh: makeIconComponentDeclaration(fal['faFaceMeh']),
    faceSmile: makeIconComponentDeclaration(fal['faFaceSmile']),
    file: makeIconComponentDeclaration(fas['faPaperclip']),
    fileAlt: makeIconComponentDeclaration(fal['faFileAlt']),
    fileDownload: makeIconComponentDeclaration(fal['faFileDownload']),
    fileExcel: makeIconComponentDeclaration(fas['faFileExcel']),
    fileImage: makeIconComponentDeclaration(fas['faFileImage']),
    fileLight: makeIconComponentDeclaration(fal['faPaperclip']),
    filePdf: makeIconComponentDeclaration(fas['faFilePdf']),
    filePlus: makeIconComponentDeclaration(fal['faFilePlus']),
    fileSolid: makeIconComponentDeclaration(fas['faFile']),
    filter: makeIconComponentDeclaration(fal['faFilter']),
    filterStrong: makeIconComponentDeclaration(fas['faFilter']),
    firefox: makeIconComponentDeclaration(fab['faFirefox']),
    first: makeIconComponentDeclaration(fas['faStepBackward']),
    folderTree: makeIconComponentDeclaration(fal['faFolderTree']),
    fs_ban: makeIconComponentDeclaration(fas['faBan']),
    fs_clock: makeIconComponentDeclaration(fas['faClock']),
    fs_microscope: makeIconComponentDeclaration(fas['faMicroscope']),
    'fs_sack-dollar': makeIconComponentDeclaration(fas['faSackDollar']),
    fs_stop: makeIconComponentDeclaration(fas['faHandPaper']),
    fs_stopwatch: makeIconComponentDeclaration(fas['faStopwatch']),
    fs_tasks: makeIconComponentDeclaration(fal['faTasks']),
    fsBars: makeIconComponentDeclaration(fas['faBars']),
    google: makeIconComponentDeclaration(fab['faGoogle']),
    gridLight: makeIconComponentDeclaration(fal['faGrid2']),
    gridSolid: makeIconComponentDeclaration(fas['faGrid2']),
    gripDots: makeIconComponentDeclaration(fal['faGripDotsVertical']),
    gripDotsH: makeIconComponentDeclaration(fal['faGripDots']),
    handPaperStrong: makeIconComponentDeclaration(fas['faHandPaper']),
    hash: makeIconComponentDeclaration(fad['faHashtag']),
    hashtag: makeIconComponentDeclaration(fal['faHashtag']),
    hide: makeIconComponentDeclaration(fal['faEyeSlash']),
    homeLgAlt: makeIconComponentDeclaration(fal['faHomeLgAlt']),
    house: makeIconComponentDeclaration(fal['faHouse']),
    houseAlt: makeIconComponentDeclaration(fal['faHouse']),
    houseBuilding: makeIconComponentDeclaration(fal['faHouseBuilding']),
    houseLaptop: makeIconComponentDeclaration(fal['faHouseLaptop']),
    houseSolid: makeIconComponentDeclaration(fas['faHouse']),
    idBadge: makeIconComponentDeclaration(fal['faIdBadge']),
    imageSlash: makeIconComponentDeclaration(fas['faImageSlash']),
    inboxOut: makeIconComponentDeclaration(fal['faInboxOut']),
    info: makeIconComponentDeclaration(fal['faInfoCircle']),
    infoCircle: makeIconComponentDeclaration(fal['faInfoCircle']),
    keyboard: makeIconComponentDeclaration(fal['faKeyboard']),
    last: makeIconComponentDeclaration(fas['faStepForward']),
    layerGroup: makeIconComponentDeclaration(fas['faLayerGroup']),
    layerPlus: makeIconComponentDeclaration(fal['faLayerPlus']),
    levelDownAlt: makeIconComponentDeclaration(fal['faLevelDownAlt']),
    levelUpAlt: makeIconComponentDeclaration(fal['faLevelUpAlt']),
    lifeRing: makeIconComponentDeclaration(fal['faLifeRing']),
    link: makeIconComponentDeclaration(fal['faLink']),
    list: makeIconComponentDeclaration(fas['faList']),
    loading: makeIconComponentDeclaration(fas['faSync']),
    locationDot: makeIconComponentDeclaration(fal['faLocationDot']),
    lock: makeIconComponentDeclaration(fal['faLock']),
    lockAlt: makeIconComponentDeclaration(fal['faLockAlt']),
    manageUser: makeIconComponentDeclaration(fal['faUserCog']),
    menu: makeIconComponentDeclaration(fas['faBars']),
    messageExclamation: makeIconComponentDeclaration(fal['faMessageExclamation']),
    messageExclamationFull: makeIconComponentDeclaration(fas['faMessageExclamation']),
    minus: makeIconComponentDeclaration(fal['faMinus']),
    mobile: makeIconComponentDeclaration(fal['faMobile']),
    next: makeIconComponentDeclaration(fal['faChevronRight']),
    paperclip: makeIconComponentDeclaration(fal['faPaperclip']),
    paragraph: makeIconComponentDeclaration(fal['faParagraph']),
    pause: makeIconComponentDeclaration(fal['faPause']),
    pauseCircle: makeIconComponentDeclaration(fal['faPauseCircle']),
    pencil: makeIconComponentDeclaration(fal['faPencil']),
    penField: makeIconComponentDeclaration(fas['faPenField']),
    percent: makeIconComponentDeclaration(fal['faPercent']),
    'person-circle-exclamation': makeIconComponentDeclaration(fal['faPersonCircleExclamation']),
    person: makeIconComponentDeclaration(fas['faMale']),
    phone: makeIconComponentDeclaration(fal['faPhone']),
    phoneAlt: makeIconComponentDeclaration(fal['faPhoneAlt']),
    phoneOffice: makeIconComponentDeclaration(fal['faPhoneOffice']),
    phonePlus: makeIconComponentDeclaration(fal['faPhonePlus']),
    piggyBank: makeIconComponentDeclaration(fad['faPiggyBank']),
    play: makeIconComponentDeclaration(fal['faPlay']),
    plug: makeIconComponentDeclaration(fal['faPlug']),
    plus: makeIconComponentDeclaration(fal['faPlus']),
    popout: makeIconComponentDeclaration(fal['faUpRightFromSquare']),
    portrait: makeIconComponentDeclaration(fal['faPortrait']),
    prev: makeIconComponentDeclaration(fal['faChevronLeft']),
    question: makeIconComponentDeclaration(fal['faQuestion']),
    rabbit: makeIconComponentDeclaration(fal['faRabbitFast']),
    radar: makeIconComponentDeclaration(fal['faRadar']),
    radioOff: makeIconComponentDeclaration(fal['faCircle']),
    radioOn: makeIconComponentDeclaration(fas['faDotCircle']),
    ratingEmpty: makeIconComponentDeclaration(fas['faStar']),
    ratingFull: makeIconComponentDeclaration(fas['faStar']),
    ratingHalf: makeIconComponentDeclaration(fas['faStarHalf']),
    redo: makeIconComponentDeclaration(fal['faRedo']),
    removeUser: makeIconComponentDeclaration(fal['faUserMinus']),
    reply: makeIconComponentDeclaration(fal['faReply']),
    rightCaret: makeIconComponentDeclaration(fas['faCaretRight']),
    robot: makeIconComponentDeclaration(fas['faRobot']),
    rulerCombined: makeIconComponentDeclaration(fal['faRulerCombined']),
    sackDollar: makeIconComponentDeclaration(fal['faSackDollar']),
    save: makeIconComponentDeclaration(fal['faSave']),
    saveAlt: makeIconComponentDeclaration(fas['faSave']),
    search: makeIconComponentDeclaration(fal['faSearch']),
    send: makeIconComponentDeclaration(fal['faPaperPlane']),
    share: makeIconComponentDeclaration(fal['faShareFromSquare']),
    shareNodes: makeIconComponentDeclaration(fal['faShareNodes']),
    sign: makeIconComponentDeclaration(fas['faSign']),
    signOut: makeIconComponentDeclaration(fal['faSignOut']),
    sliders: makeIconComponentDeclaration(fal['faSliders']),
    slidersAlt: makeIconComponentDeclaration(fas['faSliders']),
    sort: makeIconComponentDeclaration(fas['faSortUp']),
    sortArrowDown: makeIconComponentDeclaration(fas['faArrowDownWideShort']),
    sparkles: makeIconComponentDeclaration(fas['faSparkles']),
    squarePen: makeIconComponentDeclaration(fal['faSquarePen']),
    stopWatch: makeIconComponentDeclaration(fas['faStopwatch']),
    subgroup: makeIconComponentDeclaration(fas['faCaretDown']),
    success: makeIconComponentDeclaration(fas['faCheckCircle']),
    sync: makeIconComponentDeclaration(fal['faSync']),
    tachometerAltFast: makeIconComponentDeclaration(fal['faTachometerAltFast']),
    tag: makeIconComponentDeclaration(fal['faTag']),
    tagAlt: makeIconComponentDeclaration(fas['faTag']),
    tasks: makeIconComponentDeclaration(fal['faTasks']),
    thickPlus: makeIconComponentDeclaration(fas['faPlus']),
    thumbsUp: makeIconComponentDeclaration(fas['faThumbsUp']),
    timelineArrow: makeIconComponentDeclaration(fal['faTimelineArrow']),
    times: makeIconComponentDeclaration(fas['faTimes']),
    timesCircle: makeIconComponentDeclaration(fal['faTimesCircle']),
    timesLight: makeIconComponentDeclaration(fal['faTimes']),
    toilet: makeIconComponentDeclaration(fal['faToilet']),
    tools: makeIconComponentDeclaration(fal['faTools']),
    trash: makeIconComponentDeclaration(fal['faTrash']),
    trashAlt: makeIconComponentDeclaration(fal['faTrashAlt']),
    trashCan: makeIconComponentDeclaration(fal['faTrashCan']),
    trophyAlt: makeIconComponentDeclaration(fas['faTrophyAlt']),
    undoAlt: makeIconComponentDeclaration(fal['faUndoAlt']),
    unfold: makeIconComponentDeclaration(fas['faArrowsAltV']),
    upArrow: makeIconComponentDeclaration(fas['faCaretUp']),
    upload: makeIconComponentDeclaration(fal['faUpload']),
    usdCircle: makeIconComponentDeclaration(fal['faUsdCircle']),
    user: makeIconComponentDeclaration(fal['faUser']),
    userCircle: makeIconComponentDeclaration(fal['faCircleUser']),
    userCrown: makeIconComponentDeclaration(fas['faUserCrown']),
    userEdit: makeIconComponentDeclaration(fal['faUserEdit']),
    userFriends: makeIconComponentDeclaration(fal['faUserFriends']),
    userLock: makeIconComponentDeclaration(fas['faUserLock']),
    userPlus: makeIconComponentDeclaration(fal['faUserPlus']),
    users: makeIconComponentDeclaration(fal['faUsers']),
    userSecret: makeIconComponentDeclaration(fas['faUserSecret']),
    view: makeIconComponentDeclaration(fal['faEye']),
    warning: makeIconComponentDeclaration(fas['faExclamationCircle']),
    wineGlass: makeIconComponentDeclaration(fas['faWineGlass']),
}

Vue.use(Vuetify)

const vuetifyOpts: UserVuetifyPreset = {
    icons: {
        iconfont: 'faSvg',
        values: Icons,
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                //primary
                primary: '#3B35B1', // #3A3AB0
                primarylighten5: '#EBEBFF', // #E0E0FF
                primarylighten4: '#D2D2FE', // #BFBFFE
                primarylighten3: '#9F9FFD', // #9F9FFD
                primarylighten2: '#7F7FFD', // #7F7FFD
                primarylighten1: '#4D4DD1', // #4D4DD1

                primarydarken1: '#35309F',
                primarydarken2: '#2F2A8E',
                primarydarken3: '#29257C',
                primarydarken4: '#1E1B59',

                //secondary
                secondary: '#CFD6FD', // #A70A7B
                secondarylighten5: '#FBEEF8',
                secondarylighten4: '#F1C1E4',
                secondarylighten3: '#DE9BCB',
                secondarylighten2: '#E8EDFF', // #CA6CB0
                secondarylighten1: '#DBE3FF', // #B93B95

                secondarydarken1: '#A5B3FC', // #8C0867
                secondarydarken2: '#616FEF', // #750756
                secondarydarken3: '#3629CF', // #64064A
                secondarydarken4: '#000F96', // #54053E

                //Accent Black
                accentBlack: '#060A1C',
                accentBlacklighten5: '#EEEFF2',
                accentBlacklighten4: '#C5C9D1',
                accentBlacklighten3: '#8C93A2',
                accentBlacklighten2: '#535768',
                accentBlacklighten1: '#2D303D',

                //Accent White
                accentWhite: '#F8FAFF',
                accentWhitedarken1: '#EDEFF8',
                accentWhitedarken2: '#DFE3F1',
                accentWhitedarken3: '#CDD4E9',
                accentWhitedarken4: '#B8C2E0',

                //Error
                error: '#E50000',
                errorlighten5: '#FFF0F0',
                errorlighten4: '#FDD2D2',
                errorlighten3: '#F79999',
                errorlighten2: '#F46666',
                errorlighten1: '#F03333',

                errordarken1: '#BF0000',
                errordarken2: '#AA0000',
                errordarken3: '#940000',
                errordarken4: '#7F0000',

                //Success
                success: '#037c00',
                successlighten5: '#F1F8F1',
                successlighten4: '#CDE8CC',
                successlighten3: '#9AD099',
                successlighten2: '#68B966',
                successlighten1: '#35A133',

                successdarken1: '#026E00',
                successdarken2: '#026100',
                successdarken3: '#025300',
                successdarken4: '#024500',

                //Info
                info: '#0072D6',
                infolighten5: '#F5FBFF',
                infolighten4: '#C4E6FD',
                infolighten3: '#76C5FA',
                infolighten2: '#33A9F7',
                infolighten1: '#F5FBFF',

                infodarken1: '#005EAF',
                infodarken2: '#005299',
                infodarken3: '#004683',
                infodarken4: '#003B6E',

                warning: '#FA5C28',
                white: '#ffffff',
                yellowLighten: '#FFD600',

                //Background Color
                backgroundColor: '#F8FAFF',
                backgroundNew: '#F8FAFF',
            },
            dark: {
                primary: '#2b2b3a',
                secondary: '#297e7c',
            },
        },
    },
}

// interface ParsedThemeItem {
//     base: vuetifyOpts.themes.theme.light.info;
//     lighten5: vuetifyOpts.themes.theme.light.infolighten5;
//     lighten4: string,
//     lighten3: string
//     lighten2: string
//     lighten1: string
//     darken1: string
//     darken2: string
//     darken3: string
//     darken4: string
//
//     [name: string]: string
// }

export default new Vuetify(vuetifyOpts)
