

































































































import { defineComponent, ref, computed, onBeforeMount } from '@vue/composition-api'
import FeeScheduleSelect from '@/components/General/AutoCompletes/FeeScheduleSelect.vue'
import { Fee, FeeAppraiser, Lender } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserSettingsId } from '../../AppraiserProfile'
import $axios from '@/plugins/axios'
import LenderSelect from '@/components/General/AutoCompletes/LenderSelect.vue'

export default defineComponent({
    props: {
        option: {
            type: Object,
        },
    },

    components: {
        LenderSelect,
        FeeScheduleSelect,
    },
    setup() {
        const addDialog = ref<boolean>(false)
        const editDialog = ref<boolean>(false)

        const feeSchedule = ref<Fee | null>(null)
        const feeScheduleId = ref<number | null>(null)
        const feeScheduleLender = ref<Lender | null>(null)
        const feeSchedules = ref<FeeAppraiser[]>([])

        const appraiserId = computed(() => {
            return appraiserSettingsId.value
        })

        const addFeeSchedule = () => {
            editDialog.value = false
            feeSchedule.value = null
            feeScheduleId.value = null
            feeScheduleLender.value = null
            addDialog.value = true
        }

        const editFeeSchedule = (feeAppraiser: FeeAppraiser) => {
            addDialog.value = false
            feeSchedule.value = feeAppraiser.fee
            feeScheduleId.value = feeAppraiser.id
            feeScheduleLender.value = feeAppraiser.lender ?? null
            editDialog.value = true
        }

        const getAppraiserFeeSchedules = () => {
            $axios
                .get('v1/appraiser/' + appraiserId.value + '/fee-schedules')
                .then(({ data }) => {
                    if (data) feeSchedules.value = data
                })
                .catch(({ data }) => {
                    return data
                })
        }

        onBeforeMount(() => {
            getAppraiserFeeSchedules()
        })

        const save = () => {
            addDialog.value = false
            editDialog.value = false
            feeSchedule.value = null
            feeScheduleId.value = null
            feeScheduleLender.value = null

            getAppraiserFeeSchedules()

            showSnackbar('Fee Schedule(s) Updated Successfully')
        }

        return {
            addDialog,
            addFeeSchedule,
            editDialog,
            editFeeSchedule,
            feeSchedule,
            feeScheduleId,
            feeScheduleLender,
            feeSchedules,
            save,
            getAppraiserFeeSchedules,
            appraiserId,
        }
    },
})
