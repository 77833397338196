


























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { DateTime } from 'luxon'
import $axios from '@/plugins/axios'
import { AuditEvent } from '@/types/OrderAuditLog'
import EventSource from '@/components/OrderAuditLog/EventSource.vue'
import { showError } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        event: {
            type: Object as PropType<AuditEvent>,
            required: true,
        },
        auditableType: {
            type: String,
            default: '',
        },
        auditableId: {
            type: Number,
            default: 0,
        },
    },
    components: { EventSource },
    setup(props) {
        const source = ref<null | string>(null)
        const formatDate = (isoDate: string) => DateTime.fromISO(isoDate).toFormat('EEE, MMM d, t (ZZZZ)')

        watch(
            () => props.event,
            async () => {
                if (!props.event.sourceStorage) {
                    source.value = props.event.source
                    return
                }

                switch (props.event.sourceStorage.toLowerCase()) {
                    case 's3':
                        source.value = (
                            await $axios.get(
                                `/v1/${props.auditableType}/${props.auditableId}/audit-logs/${props.event.SortKey}/source`,
                            )
                        ).data
                        break
                    default:
                        showError(
                            `Not supported source storage "${props.event.sourceStorage}" in ${props.event.SortKey}`,
                        )
                        source.value = props.event.source
                }
            },
            { immediate: true },
        )

        return { source, formatDate }
    },
})
