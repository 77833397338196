












































































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { files } from '@/plugins/Files'
import { getFiles } from '@/plugins/Files'
import { File } from '@/types'
import { filesReviewed, reviewNotes } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import $axios from '@/plugins/axios'
import EditFile, {
    saveFile,
    file,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditFile.vue'
import EditFileDialog, {
    open,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditFileDialog.vue'
import { order, snapshotOrder } from '@/plugins/order/Order'
import { newFileDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AddOrderFileDialog.vue'
import { user } from '@/plugins/User'
import { openContacts } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactsDialog.vue'

export default defineComponent({
    components: { EditFileDialog, EditFile },
    props: {},
    setup() {
        const fileUrl = ref('')
        const fileIndex = ref(0)
        const filePreviewed = ref(false)

        const loading = ref(false)

        const isPdf = computed(() => {
            const regex = new RegExp('^.*.(pdf|PDF)$')

            return regex.test(files.value[fileIndex.value]?.path)
        })

        const fileTypeValid = computed(() => file.value.type !== 'Additional')

        const getColor = (status: string) => {
            switch (status) {
                case 'All':
                    return 'successdarken3'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        }

        const editFile = (file: File) => {
            open(file)
        }

        const openFile = () => {
            window.open(fileUrl.value, '_blank')
            filePreviewed.value = true
        }

        const openFileFromList = (file: File) => {
            $axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        }

        const loadFile = () => {
            loading.value = true
            filePreviewed.value = false

            file.value = JSON.parse(JSON.stringify(files.value[fileIndex.value]))

            $axios.get('/v1/file/' + files.value[fileIndex.value].id).then((response) => {
                fileUrl.value = response.data.url
                loading.value = false
                filePreviewed.value = isPdf.value
            })
        }

        const purchaseContractCheck = () => {
            if (!files.value) return
            if (files.value[fileIndex.value] && files.value[fileIndex.value].type === 'Purchase Contract') {
                reviewNotes.value = reviewNotes.value.filter((el) => el.note !== 'Please provide purchase contract')
            }
        }

        const getFile = (direction: number) => {
            purchaseContractCheck()
            if (direction > 0) saveFile()

            if (fileIndex.value + direction === files.value.length) {
                filesReviewed.value = true
                return
            }

            fileIndex.value += direction
            loadFile()
        }

        const getFileOnDelete = () => {
            if (fileIndex.value === files.value.length) {
                filesReviewed.value = true
                return
            }

            loadFile()
        }

        onMounted(() => {
            getFile(0)
        })

        const pdfUrl = process.env.VUE_APP_PDF
        return {
            editFile,
            files,
            fileIndex,
            filePreviewed,
            filesReviewed,
            fileTypeValid,
            fileUrl,
            getColor,
            getFile,
            getFileOnDelete,
            getFiles,
            isPdf,
            loading,
            newFileDialog,
            openFile,
            openFileFromList,
            order,
            pdfUrl,
            snapshotOrder,
            user,
            openContacts,
        }
    },
})
