







import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        const openKitReport = () => {
            if (!order.value?.incremental?.report_uuid) return
            window.open(
                `${process.env.VUE_APP_REPORT_EXTERNAL}beta/#/report/form/${order.value?.incremental?.report_uuid}`,
                '_blank',
            )
        }

        return {
            openKitReport,
        }
    },
})
