




















import { defineComponent, onUnmounted, PropType, ref } from '@vue/composition-api'

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import SnippetHelper from '@/helpers/snippet-helper'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'

interface EditorProps {
    value?: string
    editor: string
    toolbarItems: string[]
    readonly: boolean
    placeholder: string
    uuid: string
    height: string
    focus: boolean
}

interface FroalaHTML {
    el: Element
    html: {
        set: (arg: string) => void
    }
    edit: {
        off: () => void
    }
}

export default defineComponent({
    components: { ValidationProvider },
    props: {
        value: String,
        rules: {
            type: [Object, String],
            default: '',
        },
        uuid: {
            type: String,
            default: 'ascent-editor',
        },
        placeholder: {
            type: String,
            default: 'Start Typing',
        },
        title: {
            type: String,
        },
        hint: {
            type: String,
            default: '',
        },
        heightMin: {
            type: String,
            default: '',
        },
        heightMax: {
            type: String,
            default: '',
        },
        useLink: {
            type: Boolean,
            default: false,
        },
        toolbarOptions: {
            type: Array as PropType<string[]>,
            default: () => [
                'bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL',
                'outdent',
                'indent',
                'clearFormatting',
                'insertTable',
            ],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const textValue = ref(props.value)
        const froalaEditor = ref()
        const froalaValidator = ref()
        onUnmounted(() => {
            textValue.value = undefined
        })

        const updateFroala = (event: string) => {
            textValue.value = event
            emit('input', textValue.value)
        }

        return {
            textValue,
            froalaEditor,
            froalaValidator,
            updateFroala,
        }
    },
    data() {
        const readOnly = this.readOnly
        return {
            froalaConfig: {
                key: process.env.VUE_APP_FROALA_KEY,
                attribution: false,
                quickInsertEnabled: false,
                charCounterCount: false,
                wordCounterCount: false,
                pastePlain: true,
                placeholderText: '',
                heightMin: this.heightMin,
                heightMax: this.heightMax,
                pasteAllowedStyleProps: [
                    'border',
                    'border-bottom',
                    'border-left',
                    'border-radius',
                    'border-right',
                    'border-style',
                    'border-top',
                    'border-width',
                    'clear',
                    'display',
                    'float',
                    'padding',
                    'padding-bottom',
                    'padding-left',
                    'padding-right',
                    'padding-top',
                    'position',
                    'text-align',
                    'text-decoration',
                    'text-indent',
                    'top',
                    'vertical-align',
                    'visibility',
                    'white-space',
                ],
                zIndex: this.useLink ? '2500' : '',
                toolbarButtons: this.toolbarOptions,
                events: {
                    initialized: function () {
                        if (readOnly) {
                            // prettier-ignore
                            (this as unknown as FroalaHTML).edit.off()
                        } else {
                            SnippetHelper.init(order.value, order.value?.incremental, user.value).then((res) => {
                                if (!res) return
                                const el = (this as unknown as FroalaHTML).el

                                res.attach(el)
                            })
                        }
                    },
                    blur: () => {
                        this.$emit('blur')
                    },
                },
            },
        }
    },
    watch: {
        value(val, oldVal) {
            if (val !== oldVal && !val) {
                const validator = this.$refs.froalaValidator as InstanceType<typeof ValidationProvider>
                validator.reset()
            }
        },
    },
})
