
































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { getNotes } from '@/plugins/Notes'
import { order, directOrder, refreshOrder, updateOrder } from '@/plugins/order/Order'
import axios from '@/plugins/axios'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

function reset() {
    return {
        loading: false,
    }
}

/**
 * @name RevisionAction
 * Workflow step after order has been completed to determine if a revision request needs to happen
 *
 * @SetupData rovLink - boolean on if there is a rov link or not
 */

export default defineComponent({
    setup() {
        const rovLink = ref(false)
        const orderAdditionalProducts = ref(null)
        const rovAllowed = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !AscentHelper.featureEnabled('monitoring.ROVAllowedAfterSignOff')
            ) {
                return true
            }

            return (
                order.value.incremental.hasCurrentAppraisalPdfBeenSignedOff &&
                order.value.incremental.hasCurrentBorrowerDeliveryBeenSent
            )
        })

        onMounted(() => {
            if (!order.value) return
            axios.get('v1/lender/' + order.value.lender_id + '/option/reconsideration-of-value').then((response) => {
                if (response.data === '') {
                    return rovLink.value
                }
                if (response.data.option_value.rovLink) {
                    return (rovLink.value = response.data.option_value.rovLink)
                }
                rovLink.value = false
            })

            axios
                .get('/v1/settings/order_additional_products')
                .then((res) => {
                    if (res.data && res.data.result && user.value?.type === 'client') {
                        orderAdditionalProducts.value = res.data.result
                    }
                })
                .catch(() => {
                    orderAdditionalProducts.value = null
                })
        })

        return {
            order,
            rovAllowed,
            rovLink,
            user,
            directOrder,
            orderAdditionalProducts,
        }
    },
    data() {
        return reset()
    },
    computed: {
        /**
         * Url of reconsideration
         */
        reconsiderationURL(): string {
            if (!order.value) return ''
            if (!order.value.incremental) return ''
            return '/appraisal/reconsideration/' + order.value.id + '/' + order.value.incremental.token
        },
    },
    methods: {
        /**
         * Escalates order and puts it back into the QC review pool
         */
        escalate() {
            confirm(
                'Escalate File For Review?',
                'Would you like to put this file back in QC to be Reviewed again?',
            ).then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    this.$axios
                        .post('/v1/quality/action/' + order.value.id + '/escalate-order-for-review')
                        .then((data) => {
                            if (!order.value) return
                            let tags = order.value.tags
                            if (tags == null) {
                                tags = []
                            }
                            tags.push(data.data.tag)
                            updateOrder()
                            getNotes(order.value.id)
                            showSnackbar('File Escalated successfully')
                            Object.assign(this.$data, reset())
                        })
                }
            })
        },
        /**
         * Submits a revision request with note. Refreshes order after submitting
         */
        submitRevision() {
            if (!order.value) return
            if (!order.value.incremental) return
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/revision-request', {})
                .then(() => {
                    showSnackbar('Revision Request Submitted Successfully!')
                    refreshOrder()
                    Object.assign(this.$data, reset())
                })
                .catch(() => {
                    this.loading = false
                    showError('Sorry! Something went wrong.')
                })
        },
    },
})
