














































import { getFiles } from '@/plugins/Files'
import { defineComponent, onBeforeMount, Ref, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import FileUpload from '@/components/General/FileUpload.vue'
import { user } from '@/plugins/User'
import { File } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { fileStatuses, systemFileTypes, VisibilityData } from '@/plugins/Data'
import $axios from 'axios'

const fileStatusesSelection = ref('All')
export const newFileDialog = ref(false)

function reset() {
    return {
        added: false,
        loading: false,
        file_type: '',
        allowed: ['.pdf', '.doc', '.docx', '.xml', '.xlsx', '.xls', '.env', '.pdco'],
    }
}

const fileTypes: Ref<string[]> = ref([])
const fileVisibility: Ref<VisibilityData> = ref({})
export default defineComponent({
    name: 'AddOrderFileDialog',
    components: {
        ValidationObserver,
        FileUpload,
    },
    setup() {
        fileStatuses.splice(4, 1)
        onBeforeMount(() => {
            $axios.get('v1/settings/file-list').then((res) => {
                if (!res.data) return
                fileTypes.value = res.data.result
            })
            $axios.get('v1/settings/file-visibility').then((res) => {
                if (!res.data) return
                fileVisibility.value = res.data.result
            })
        })
        return {
            newFileDialog,
            user,
            fileStatuses,
            fileStatusesSelection,
        }
    },
    data() {
        return reset()
    },
    computed: {
        url() {
            return 'order/' + order.value?.id
        },
        filteredFileTypes() {
            if (user.value !== undefined && user.value.type !== 'appraiser')
                return systemFileTypes.value.concat(fileTypes.value).sort()
            return ['Additional']
        },
        filteredFileStatuses() {
            if (fileVisibility.value[this.file_type]) {
                return fileVisibility.value[this.file_type as string]
            }
            return fileStatuses
        },
    },
    methods: {
        uploadFile() {
            this.loading = true
            const fileUpload = this.$refs.fileUpload as Vue & { triggerUpload: () => void }
            fileUpload.triggerUpload()
        },
        cancel() {
            const fileUpload = this.$refs.fileUpload as Vue & { resetFiles: () => void }
            fileUpload.resetFiles()
            this.newFileDialog = false
        },
        saveFile(file: File) {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/file', {
                    type: this.file_type,
                    path: file.path,
                    status: fileStatusesSelection.value,
                })
                .then(() => {
                    if (!order.value) return
                    this.newFileDialog = false
                    Object.assign(this.$data, reset())
                    getFiles(order.value.id)
                    const fileUpload = this.$refs.fileUpload as Vue & { resetFiles: () => void }
                    fileUpload.resetFiles()
                    showSnackbar('File Uploaded Successfully')
                })
                .catch((res) => {
                    if (!res.response.data.message) return
                    if (!order.value) return
                    this.newFileDialog = false
                    Object.assign(this.$data, reset())
                    getFiles(order.value.id)
                    const fileUpload = this.$refs.fileUpload as Vue & { resetFiles: () => void }
                    fileUpload.resetFiles()
                    showSnackbar(res.response.data.message, 'red')
                    return
                })
        },
    },
})
