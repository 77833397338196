



























































import { ValidationObserver } from 'vee-validate'
import { defineComponent } from '@vue/composition-api'
import { VerosOverrides } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, getVeros } from '@/plugins/order/Order'

interface Types {
    stop: string
    code: string
    reason: string
}

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
    components: {
        ValidationObserver,
    },
    data() {
        return {
            dialog: false,
            saving: false,
            success: false,
            override: {} as VerosOverrides | undefined,
            code: null,
            comment: null,
            verosCount: 0,
            types: [
                {
                    stop: '302',
                    code: 'RQ30201',
                    reason: 'Recent/new construction',
                },
                {
                    stop: '302',
                    code: 'RQ30202',
                    reason: 'Subject address located in rural area',
                },
                {
                    stop: '302',
                    code: 'RQ30203',
                    reason: 'Address has recently changed and therefore may not be in your database',
                },
                {
                    stop: '302',
                    code: 'RQ30204',
                    reason: 'Address is correct and verified via local professional',
                },
                {
                    stop: '302',
                    code: 'RQ30205',
                    reason: 'Address is correct and verified by lender',
                },
                {
                    stop: '302',
                    code: 'RQ30299',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FNM0179',
                    code: 'RQFN01',
                    reason: 'Lender has a variance from Fannie Mae',
                },
                {
                    stop: 'FNM0179',
                    code: 'RQFN02',
                    reason: 'Lender confirmed that the order meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0179',
                    code: 'RQFN03',
                    reason: 'Lender confirmed that the property meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0179',
                    code: 'RQFN04',
                    reason: 'Lender validated that the message fired in error.',
                },
                {
                    stop: 'FNM0179',
                    code: 'RQFN05',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FNM0193',
                    code: 'RQFN05',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FNM0197',
                    code: 'RQFN01',
                    reason: 'Lender has a variance from Fannie Mae',
                },
                {
                    stop: 'FNM0197',
                    code: 'RQFN02',
                    reason: 'Lender confirmed that the order meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0197',
                    code: 'RQFN03',
                    reason: 'Lender confirmed that the property meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0197',
                    code: 'RQFN04',
                    reason: 'Lender validated that the message fired in error.',
                },
                {
                    stop: 'FNM0197',
                    code: 'RQFN05',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FNM0399',
                    code: 'RQFN05',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FNM0174',
                    code: 'RQFN01',
                    reason: 'Lender has a variance from Fannie Mae',
                },
                {
                    stop: 'FNM0174',
                    code: 'RQFN02',
                    reason: 'Lender confirmed that the order meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0174',
                    code: 'RQFN03',
                    reason: 'Lender confirmed that the property meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0174',
                    code: 'RQFN04',
                    reason: 'Lender validated that the message fired in error.',
                },
                {
                    stop: 'FNM0174',
                    code: 'RQFN05',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FNM0083',
                    code: 'RQFN01',
                    reason: 'Lender has a variance from Fannie Mae',
                },
                {
                    stop: 'FNM0083',
                    code: 'RQFN02',
                    reason: 'Lender confirmed that the order meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0083',
                    code: 'RQFN03',
                    reason: 'Lender confirmed that the property meets Fannie Mae requirements',
                },
                {
                    stop: 'FNM0083',
                    code: 'RQFN04',
                    reason: 'Lender validated that the message fired in error.',
                },
                {
                    stop: 'FNM0083',
                    code: 'RQFN05',
                    reason: 'Other (see comments)',
                },
                {
                    stop: 'FHA201',
                    code: 'RQFHA20101',
                    reason: 'Lender validated that information is correct and appraiser is on the FHA Appraiser Roster',
                },
                {
                    stop: 'FHA201',
                    code: 'RQFHA20199',
                    reason: 'Other (see comments)',
                },
            ] as Types[],
            submit_id: null,
        }
    },
    computed: {
        overrideItems(): VerosOverrides[] | Types[] | string {
            if (this.override == null) return []
            return (this.types as Types[]).filter((elem) => elem.stop === (this.override as VerosOverrides).code)
        },
    },
    methods: {
        open(override: VerosOverrides) {
            this.dialog = true
            this.override = override
        },
        save() {
            if (!order.value) return
            this.saving = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/veros/override', {
                    submission_id: this?.override?.submission_id,
                    appraisal: this?.override?.name,
                    reason: this.code,
                    comment: this.comment,
                    code: (this.override as VerosOverrides).code,
                })
                .then(() => {
                    this.success = true
                    this.checkForProcess()
                })
        },
        checkForProcess() {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/veros/check', {
                    id: (this.override as VerosOverrides).submission_id,
                })
                .then((response) => {
                    if (response.data.result === 'finished') {
                        this.dialog = false
                        this.saving = false
                        this.override = {} as VerosOverrides | undefined
                        this.code = null
                        this.comment = null
                        showSnackbar('Override Processed Successfully.')
                        getVeros()
                    } else {
                        this.verosCount += 5
                        setTimeout(() => {
                            this.checkForProcess()
                        }, 5000)
                    }
                })
        },
    },
})
